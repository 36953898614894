import * as types from './constants'

export const fetchMembers = (projectId) => ({
  projectId,
  type: types.FETCH_LIST_REQUEST,
})

fetchMembers.success = (projectId, payload) => ({
  ...payload,
  projectId,
  type: types.FETCH_LIST_SUCCESS,
})

fetchMembers.failure = (projectId, error) => ({
  error,
  projectId,
  type: types.FETCH_LIST_FAILURE,
})

export const addMember = (projectId, email, role, resolve = null, reject = null) => ({
  projectId,
  member: {
    email,
    role,
  },
  resolve,
  reject,
  type: types.ADD_REQUEST,
})

addMember.success = (projectId, payload) => ({
  ...payload,
  projectId,
  type: types.ADD_SUCCESS,
})

addMember.failure = (projectId, error) => ({
  error,
  projectId,
  type: types.ADD_FAILURE,
})

export const removeMember = (projectId, memberId) => ({
  projectId,
  memberId,
  type: types.REMOVE_REQUEST,
})

removeMember.success = (projectId, memberId) => ({
  projectId,
  memberId,
  type: types.REMOVE_SUCCESS,
})

removeMember.failure = (projectId, memberId, error) => ({
  error,
  projectId,
  memberId,
  type: types.REMOVE_FAILURE,
})
