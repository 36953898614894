import { createSelector } from 'reselect'

const getProjectsById = (state) => state.projects.byId
const getProjectIds = (state) => state.projects.ids

export const isFetching = (state) => state.projects.isFetching
export const isCreating = (state) => state.projects.isCreating
export const getProjectsError = (state) => state.projects.error
export const getNextPageOffset = (state) => state.projects.nextPageOffset
export const getTotal = (state) => state.projects.total
export const getArchivedStateFilter = (state) => state.projects.archivedStateFilter

export const getProjects = createSelector(getProjectsById, getProjectIds, (projects, ids) =>
  ids.map((id) => projects[id])
)

export const getProject = createSelector(
  getProjectsById,
  (state, projectId) => projectId,
  (projects, projectId) => projects[projectId]
)
