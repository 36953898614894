const genericError =
  'Извините, возникла неизвестная ошибка. Повторите попытку и обратитесь в службу поддержки, если ошибка сохранится.'

const messages = {
  'update_task.login_pattern':
    'Логин должен содержать только латиницу в нижнем регистре, цифры и символы "_", ".", "-"',
  'new_member.form.error.403': 'У вас недостаточно прав для выполнения этой операции.',
  'new_member.form.error.404': 'Пользователь не найден.',
  'new_member.form.error.409': 'У пользователя уже есть доступ к текущему проекту.',
  'new_member.form.error.generic': genericError,
  'new_member.form.email.error.format': 'Неверный email',
  'remove_member.error.403': 'У вас недостаточно прав для выполнения этой операции.',
  'remove_member.error.409': 'Вы не можете удалить этого пользователя.',
  'remove_member.error.generic': genericError,
}

export default (key) => messages[key] || key
