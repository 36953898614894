import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, Grid, Message } from 'semantic-ui-react'
import useFetch from '../../hooks/useFetch'
import isGranted from '../../hoc/isGranted'
import { ADMIN, OWNER } from '../../constants/user-roles'
import * as api from '../../api/yandexMetrika'

const itemsToOptions = (items) =>
  items
    .filter((item) => item.status !== 'Deleted')
    .map((item) => ({
      key: `${item.id}`,
      text: `${item.id}`,
      value: item.id,
      description: item.name,
    }))

const ChooseYandexMetrikaCounter = ({ projectId, tokenOwnerId, isUpdating, select }) => {
  const [selectedCounter, setCounter] = useState(null)
  const fetchCounters = useCallback(() => {
    return api.fetchCounters(projectId, tokenOwnerId)
  }, [projectId, tokenOwnerId])
  const [counters, countersIsFetching] = useFetch(fetchCounters, [])

  const onChangeItem = (e, { value }) => {
    setCounter(value)
  }

  const onSave = selectedCounter
    ? () => {
        select(tokenOwnerId, selectedCounter)
      }
    : null

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={14}>
          <Dropdown
            placeholder="Выберите счетчик..."
            fluid
            search
            selection
            loading={countersIsFetching}
            options={itemsToOptions(counters)}
            onChange={onChangeItem}
          />
        </Grid.Column>
        <Grid.Column width={2}>
          <Button
            primary
            content="Сохранить"
            disabled={!selectedCounter}
            loading={isUpdating}
            onClick={onSave}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

ChooseYandexMetrikaCounter.defaultProps = {
  isUpdating: false,
}

ChooseYandexMetrikaCounter.propTypes = {
  projectId: PropTypes.string.isRequired,
  tokenOwnerId: PropTypes.string.isRequired,
  select: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
}

const NoRights = () => <Message>Процесс интеграции не завершён: счётчик не выбран.</Message>

export default isGranted([OWNER, ADMIN])(ChooseYandexMetrikaCounter, NoRights)
