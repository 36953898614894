import { createGlobalStyle } from 'styled-components/macro'
import 'semantic-ui-css/semantic.min.css'
import { COLOR_BLACK } from './variables'

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto+Mono|Roboto:400&subset=cyrillic');

  body {
    color: ${COLOR_BLACK};
    background: linear-gradient(#f7f7f7, #fbfbfb);
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow-y: scroll;
  }

  button {
    border: unset;
    outline: unset;
    background: unset;
  }

  .no-decoration {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.9) !important;

    &:hover {
      color: #fff !important;
    }
  }

  .no-opacity {
    opacity: 1 !important;
  }

  .text-bold {
    font-size: 1.15em;
    font-weight: bold;
  }
  
  .ui {
    font-family: 'Roboto', sans-serif !important;

    textarea,
    input,
    .header,
    .heading,
    .title,
    .active {
      font-family: 'Roboto', sans-serif !important;
    }
  }

  .monospace {
    font-family: 'Roboto Mono', monospace;
  }
`
