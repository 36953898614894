import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@airmedia/api-utils'
import getMessage from '../../utils/getMessage'
import { notifyError } from '../notifications'
import * as actions from './actions'
import * as types from './constants'
import * as api from '../../api/members'

export function* loadMembers({ projectId }) {
  try {
    const payload = yield call(api.fetchMembers, projectId)
    yield put(actions.fetchMembers.success(projectId, payload))
  } catch (err) {
    yield put(actions.fetchMembers.failure(projectId, err))
  }
}

export function* addMember({ projectId, member, resolve, reject }) {
  try {
    const payload = yield call(api.addMember, projectId, member)
    yield put(actions.addMember.success(projectId, payload))

    if (resolve) {
      yield call(resolve)
    }
  } catch (err) {
    yield put(actions.addMember.failure(projectId, err))

    if (reject) {
      yield call(reject, err)
    }
  }
}

export function* removeMember({ projectId, memberId }) {
  try {
    yield call(api.deleteMember, projectId, memberId)
    yield put(actions.removeMember.success(projectId, memberId))
  } catch (err) {
    yield put(actions.removeMember.failure(projectId, memberId, err))

    let errCode = 'generic'
    if (err instanceof ApiError && (err.status === 403 || err.status === 409)) {
      errCode = err.status
    }

    yield put(
      notifyError(getMessage(`remove_member.error.${errCode}`), 'Удаление участника проекта')
    )
  }
}

function* watchLoadMembers() {
  yield takeLatest(types.FETCH_LIST_REQUEST, loadMembers)
}

function* watchAddMember() {
  yield takeEvery(types.ADD_REQUEST, addMember)
}

function* watchRemoveMember() {
  yield takeEvery(types.REMOVE_REQUEST, removeMember)
}

export function* membersSaga() {
  yield all([watchLoadMembers(), watchAddMember(), watchRemoveMember()])
}
