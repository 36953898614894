import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Icon } from 'semantic-ui-react'
import * as integration from '../../constants/integrations'

const StyledIcon = styled(Icon)`
  margin-left: 0.25rem !important;
`

const RevokedTokenIcon = ({ status }) => {
  if (status === integration.STATUS_REVOKED) {
    return <StyledIcon name="exclamation triangle" color="yellow" title="Токен недействителен" />
  }

  return null
}

RevokedTokenIcon.propTypes = {
  status: PropTypes.string.isRequired,
}

export default RevokedTokenIcon
