import { getMenuItems } from './project-menus'

const UUID_RE = '[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}'
const projectSections = getMenuItems()
  .map((x) => x.section)
  .join('|')

export const AUTH_PAGE = '/auth'
export const INDEX_PAGE = '/'
export const PROJECT_INDEX_PAGE = `/:projectId(${UUID_RE})`
export const PROJECT_PAGE = `/:projectId(${UUID_RE})/:section(${projectSections})`
export const NEW_PROJECT_PAGE = '/new-project'
