import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components/macro'
import { Message } from 'semantic-ui-react'
import * as notificationTypes from '../constants/notification-types'

const ANIMATION_DURATION = 300

const blink = keyframes`
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  to {
    opacity: 1;
  }
`

const animationRule = css`
  ${blink} 0.3s linear;
`

const NotificationWrap = styled.div`
  margin-bottom: 10px;
  text-align: right;
  transition: 0.3s;
  opacity: ${(props) => props.opacity};
  cursor: default;

  & .ui.message {
    padding-right: 30px !important;
    text-align: center;
    word-break: break-word;

    &:hover {
      animation: ${animationRule};
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    }
  }

  & .icon.close {
    top: 0.5em !important;
  }
`

class Notification extends Component {
  state = {
    opacity: 1,
  }

  componentDidMount() {
    this.setNotificationTimeout()
  }

  setNotificationTimeout = () => {
    const { timeout } = this.props
    if (timeout && !this.timeoutId) {
      this.timeoutId = setTimeout(this.hideNotification, timeout)
    }
  }

  stopNotificationTimeout = () => {
    clearTimeout(this.timeoutId)
    this.timeoutId = null
  }

  hideNotification = () => {
    const { id } = this.props
    this.setState({ opacity: 0 })
    this.timeoutId = setTimeout(() => this.props.removeNotification(id), ANIMATION_DURATION)
  }

  handleClick = () => {
    this.stopNotificationTimeout()
    this.hideNotification()
  }

  render() {
    const { notificationType, header, content } = this.props
    const { opacity } = this.state
    const options = {}

    switch (notificationType) {
      case notificationTypes.SUCCESS:
        options.positive = true
        break
      case notificationTypes.ERROR:
        options.negative = true
        break
      case notificationTypes.WARN:
        options.warning = true
        break
      case notificationTypes.INFO:
      default:
        options.info = true
    }

    return (
      <NotificationWrap opacity={opacity}>
        <Message
          compact
          {...options}
          onDismiss={this.handleClick}
          onMouseEnter={this.stopNotificationTimeout}
          onMouseLeave={this.setNotificationTimeout}
        >
          {header && <Message.Header>{header}</Message.Header>}
          <Message.Content>{content}</Message.Content>
        </Message>
      </NotificationWrap>
    )
  }
}

Notification.propTypes = {
  id: PropTypes.number.isRequired,
  timeout: PropTypes.number.isRequired,
  notificationType: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  removeNotification: PropTypes.func.isRequired,
}

export default Notification
