import React from 'react'
import PropTypes from 'prop-types'
import { Form, Button } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form'
import { makeMinLength } from '../../utils/validators'
import InputField from './InputField'

const minLength3 = makeMinLength(3, 'Название проекта должно содержать не менее 3 символов')

const NewProjectForm = ({ isCreating, handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <Field
      name="name"
      component={InputField}
      validate={[minLength3]}
      width={16}
      required
      label="Название проекта"
      placeholder="Новый проект"
    />
    <Button content="Создать" loading={isCreating} />
  </Form>
)

NewProjectForm.propTypes = {
  isCreating: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'newProject',
})(NewProjectForm)
