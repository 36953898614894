import { camelizeKeys } from 'humps'
import callApi from './callApi'

const getData = (payload) => payload.data.map((item) => camelizeKeys(item))

export async function fetchClients(projectId) {
  const payload = await callApi('GET', `/api/projects/${projectId}/yandex-direct/clients`)

  return getData(payload)
}

export async function disable(projectId) {
  return callApi('PUT', `/api/projects/${projectId}/yandex-direct/disable`)
}

export async function enable(projectId) {
  return callApi('DELETE', `/api/projects/${projectId}/yandex-direct/disable`)
}
