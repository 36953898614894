const prefix = '@members/'

export const FETCH_LIST_REQUEST = `${prefix}FETCH_LIST_REQUEST`
export const FETCH_LIST_SUCCESS = `${prefix}FETCH_LIST_SUCCESS`
export const FETCH_LIST_FAILURE = `${prefix}FETCH_LIST_FAILURE`

export const ADD_REQUEST = `${prefix}ADD_REQUEST`
export const ADD_SUCCESS = `${prefix}ADD_SUCCESS`
export const ADD_FAILURE = `${prefix}ADD_FAILURE`

export const REMOVE_REQUEST = `${prefix}REMOVE_REQUEST`
export const REMOVE_SUCCESS = `${prefix}REMOVE_SUCCESS`
export const REMOVE_FAILURE = `${prefix}REMOVE_FAILURE`
