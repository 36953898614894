import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Button, Icon, Table } from 'semantic-ui-react'
import { getReadableRole } from '../constants/user-roles'

const StyledTableRow = styled(Table.Row)`
  .email {
    max-width: 26.5vw;
    display: inline-block;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .remove-btn {
    transition: transform 0.15s;

    &:hover {
      transform: scale(1.1);
    }

    &:active {
      opacity: 0.7;
    }
  }
`

const MemberItem = ({ id, email, role, isRemoving, onRemove }) => {
  return (
    <StyledTableRow>
      <Table.Cell width={8}>
        <span className="email">{email}</span>
      </Table.Cell>
      <Table.Cell width={5}>{getReadableRole(role)}</Table.Cell>
      <Table.Cell width={3} textAlign="center">
        <Button
          className="remove-btn"
          basic
          negative
          icon
          circular
          size="small"
          loading={isRemoving}
          disabled={isRemoving}
          onClick={() => onRemove(id)}
        >
          <Icon name="user delete" />
        </Button>
      </Table.Cell>
    </StyledTableRow>
  )
}

MemberItem.defaultProps = {
  isRemoving: false,
}

MemberItem.propTypes = {
  id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  isRemoving: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
}

export default MemberItem
