import * as types from './constants'
import * as notificationTypes from '../../constants/notification-types'

export const addNotification = (notificationType, header, content, timeout) => ({
  type: types.ADD,
  notificationType,
  header,
  content,
  timeout,
})

export const notifyError = (message, header = null, timeout = 15000) =>
  addNotification(notificationTypes.ERROR, header, message, timeout)

export const removeNotification = (id) => ({
  type: types.REMOVE,
  id,
})
