import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withInfiniteScroll } from 'pannier'
import ProjectsList from '../components/ProjectsList'
import {
  fetchProjects,
  getNextPageOffset,
  getProjects,
  getProjectsError,
  getTotal,
  isFetching,
  unarchiveProject,
} from '../stores/projects'

const ProjectsListInfiniteScroll = withInfiniteScroll(ProjectsList)

const mapStateToProps = (state) => ({
  projects: getProjects(state),
  isFetching: isFetching(state),
  error: getProjectsError(state),
  total: getTotal(state),
  nextPageOffset: getNextPageOffset(state),
})

class ProjectsListContainer extends Component {
  componentDidMount() {
    const { nextPageOffset, fetchProjects } = this.props

    if (!nextPageOffset) {
      fetchProjects()
    }
  }

  render() {
    const { projects, isFetching, error, fetchProjects, total, unarchiveProject } = this.props
    const trackScroll = !error.requestFailed && !isFetching && projects.length < total

    return (
      <ProjectsListInfiniteScroll
        fetchData={fetchProjects}
        trackScroll={trackScroll}
        projects={projects}
        isFetching={isFetching}
        unarchiveProject={unarchiveProject}
      />
    )
  }
}

ProjectsListContainer.defaultProps = {
  nextPageOffset: 0,
}

ProjectsListContainer.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool.isRequired,
  fetchProjects: PropTypes.func.isRequired,
  error: PropTypes.shape({
    requestId: PropTypes.string,
    requestFailed: PropTypes.bool.isRequired,
  }).isRequired,
  total: PropTypes.number.isRequired,
  unarchiveProject: PropTypes.func.isRequired,
  nextPageOffset: PropTypes.number,
}

export default connect(mapStateToProps, { fetchProjects, unarchiveProject })(ProjectsListContainer)
