import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { Button, Container, Dropdown, Grid, Icon, Message } from 'semantic-ui-react'
import ProjectsList from '../../containers/ProjectsList'
import * as api from '../../api/projects'
import * as routes from '../../constants/routes'
import Spinner from '../Spinner'

const StyledContainer = styled(Container)`
  padding-top: 20px;

  .ui.loader.active {
    margin-top: 20vh;
  }
`

const filterOptions = [
  {
    key: api.ARCHIVED_STATE_ALL,
    text: 'Показывать все',
    value: api.ARCHIVED_STATE_ALL,
  },
  {
    key: api.ARCHIVED_STATE_NOT_ARCHIVED,
    text: 'Показывать только активные',
    value: api.ARCHIVED_STATE_NOT_ARCHIVED,
  },
  {
    key: api.ARCHIVED_STATE_ARCHIVED,
    text: 'Показывать только архивные',
    value: api.ARCHIVED_STATE_ARCHIVED,
  },
]

const IndexPage = ({ archivedState, setArchivedState, isFetching, total }) => {
  const handleChangeFilter = useCallback(
    (e, { value }) => {
      setArchivedState(value)
    },
    [setArchivedState]
  )

  return (
    <StyledContainer>
      <Grid columns={1} centered>
        <Grid.Row>
          <Grid.Column width={10}>
            <Grid verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={8}>
                  <Button as={Link} to={routes.NEW_PROJECT_PAGE} basic positive>
                    <Icon name="add square" />
                    Создать проект
                  </Button>
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                  <Dropdown
                    options={filterOptions}
                    value={archivedState}
                    onChange={handleChangeFilter}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {!isFetching && !total ? (
              <Message info icon>
                <Icon name="info circle" />
                <Message.Content>
                  <Message.Header>Нет данных</Message.Header>
                  <p>Не найдено ни одного проекта.</p>
                  <p>Попробуйте изменить условия показа или создайте новый проект.</p>
                </Message.Content>
              </Message>
            ) : null}
            <ProjectsList />
            {isFetching && <Spinner inline />}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </StyledContainer>
  )
}

IndexPage.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  archivedState: PropTypes.string.isRequired,
  setArchivedState: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
}

export default IndexPage
