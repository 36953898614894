import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Grid, Image, Segment } from 'semantic-ui-react'
import Breadcrumbs from '../containers/Breadcrumbs'
import svgLogo from '../assets/logo.svg'
import LoginButton from '../containers/LoginButton'

const Header = ({ match }) => (
  <Segment attached inverted>
    <Grid container verticalAlign="middle">
      <Grid.Row>
        <Grid.Column width={1}>
          <Image as={Link} to="/" src={svgLogo} alt="project" size="mini" />
        </Grid.Column>
        <Grid.Column width={11} textAlign="center">
          <Breadcrumbs match={match} />
        </Grid.Column>
        <Grid.Column width={4} floated="right" textAlign="right">
          <LoginButton />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
)

Header.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    params: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
}

export default Header
