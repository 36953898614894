import React, { Component } from 'react'
import hoistStatics from 'hoist-non-react-statics'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isAuth } from '@airmedia/auth'
import { getProject } from '../stores/projects'

const getDisplayName = (WrappedComponent) => {
  if (WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component'
  }

  return 'null'
}

const isGranted = (allowedRoles, options) => (WrappedComponent, FallbackComponent) => {
  const mapStateToProps = (state, { projectId }) => {
    const project =
      getProject(state, projectId) ||
      (options && options.projectId && getProject(state, options.projectId))

    return {
      userRole: project && project.userRole,
    }
  }

  class Wrapper extends Component {
    render() {
      const { userRole } = this.props
      const isAuthorized = isAuth()

      if (isAuthorized && userRole && allowedRoles.includes(userRole)) {
        return <WrappedComponent {...this.props} />
      }

      return (FallbackComponent && <FallbackComponent {...this.props} />) || null
    }
  }

  Wrapper.displayName = `isGranted(${getDisplayName(WrappedComponent)})`
  Wrapper.WrappedComponent = WrappedComponent

  Wrapper.defaultProps = {
    userRole: null,
  }

  Wrapper.propTypes = {
    userRole: PropTypes.string,
  }

  return connect(mapStateToProps)(hoistStatics(Wrapper, WrappedComponent))
}

export default isGranted
