import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as types from './constants'
import * as selectors from './selectors'
import * as api from '../../api/projects'
import * as yandexDirectApi from '../../api/yandexDirect'
import * as yandexMetrikaApi from '../../api/yandexMetrika'
import * as googleAnalyticsApi from '../../api/googleAnalytics'
import * as integrations from '../../constants/integrations'
import openDialog from '../../utils/openWin'
import { absoluteUrl } from '../../utils/url'

const DIALOG_WINDOW_WIDTH = 600
const DIALOG_WINDOW_HEIGHT = 600

const getNextPageOffset = ({ total, limit, offset }) =>
  total > 0 ? Math.min(offset + limit, total) : 0

const resolveIntegrationStatus = (project, service, params) => {
  if (!project) {
    return null
  }

  switch (service) {
    case integrations.SERVICE_GOOGLE_ANALYTICS:
      return project.googleAnalyticsStatus
    case integrations.SERVICE_YANDEX_DIRECT:
      return project.yandexDirectStatus
    case integrations.SERVICE_YANDEX_METRIKA: {
      if (!params.counterId) {
        return integrations.STATUS_UNCONNECTED
      }

      const integration = project.yandexMetrika.find((item) => item.counterId === params.counterId)
      return integration ? integration.status : null
    }
    default:
      throw new Error(`Unsupported service: ${service}`)
  }
}

const extractProjectFromPayload = (projectId, payload) => payload.entities.projects[projectId]

const enableIntegration = (projectId, service, params) => {
  if (service === integrations.SERVICE_GOOGLE_ANALYTICS) {
    return googleAnalyticsApi.enable(projectId)
  }
  if (service === integrations.SERVICE_YANDEX_DIRECT) {
    return yandexDirectApi.enable(projectId)
  }
  if (service === integrations.SERVICE_YANDEX_METRIKA) {
    return yandexMetrikaApi.enable(projectId, params.counterId)
  }
  throw new Error(`Unsupported service: ${service}`)
}

export function* applyArchivedStateFilter({ archivedState }) {
  try {
    yield put(actions.fetchProjects.request())
    const payload = yield call(api.fetchProjects, archivedState, 0)

    yield put(actions.fetchProjects.success(payload, getNextPageOffset(payload.meta || {})))
  } catch (err) {
    yield put(actions.fetchProjects.failure(err))
  }
}

export function* loadProjects() {
  try {
    const isFetching = yield select(selectors.isFetching)

    if (isFetching) {
      return
    }

    const offset = yield select(selectors.getNextPageOffset)
    const archivedState = yield select(selectors.getArchivedStateFilter)

    yield put(actions.fetchProjects.request())
    const payload = yield call(api.fetchProjects, archivedState, offset)

    yield put(actions.fetchProjects.success(payload, getNextPageOffset(payload.meta || {})))
  } catch (err) {
    yield put(actions.fetchProjects.failure(err))
  }
}

export function* loadProjectDetails({ projectId, force }) {
  try {
    if (!force) {
      const project = yield select(selectors.getProject, projectId)
      if (project && project.isFetching) {
        return
      }
    }

    yield put(actions.fetchProjectDetails.request(projectId))
    const payload = yield call(api.fetchProjectDetails, projectId)

    yield put(actions.fetchProjectDetails.success(projectId, payload))
  } catch (err) {
    yield put(actions.fetchProjectDetails.failure(projectId, err))
  }
}

export function* createProject({ project, callback }) {
  try {
    const payload = yield call(api.createProject, project)
    yield put(actions.createProject.success(payload.result, payload))

    if (callback) {
      yield call(callback, payload.result)
    }
  } catch (err) {
    yield put(actions.createProject.failure(err))
  }
}

export function* updateProject({ projectId, project }) {
  try {
    const payload = yield call(api.updateProject, projectId, project)
    yield put(actions.updateProject.success(projectId, payload))
  } catch (err) {
    yield put(actions.updateProject.failure(projectId, err))
  }
}

export function* archiveProject({ projectId, meta }) {
  try {
    yield call(api.archiveProject, projectId)

    const archivedState = yield select(selectors.getArchivedStateFilter)
    yield put(
      actions.archiveProject.success(projectId, archivedState === api.ARCHIVED_STATE_NOT_ARCHIVED)
    )

    if (meta.onSuccess) {
      yield call(meta.onSuccess)
    }
  } catch (err) {
    yield put(actions.archiveProject.failure(projectId, err))
  }
}

export function* unarchiveProject({ projectId }) {
  try {
    yield call(api.unarchiveProject, projectId)
    const archivedState = yield select(selectors.getArchivedStateFilter)
    yield put(
      actions.unarchiveProject.success(projectId, archivedState === api.ARCHIVED_STATE_ARCHIVED)
    )
  } catch (err) {
    yield put(actions.unarchiveProject.failure(projectId, err))
  }
}

export function* disableYandexDirect({ projectId }) {
  try {
    yield call(yandexDirectApi.disable, projectId)
    yield put(actions.disableYandexDirect.success(projectId))
  } catch (err) {
    yield put(actions.disableYandexDirect.failure(projectId, err))
  }
}

export function* disableYandexMetrika({ projectId, counterId }) {
  try {
    yield call(yandexMetrikaApi.disable, projectId, counterId)
    yield put(actions.disableYandexMetrika.success(projectId, counterId))
  } catch (err) {
    yield put(actions.disableYandexMetrika.failure(projectId, counterId, err))
  }
}

export function* disableGoogleAnalytics({ projectId }) {
  try {
    yield call(googleAnalyticsApi.disable, projectId)
    yield put(actions.disableGoogleAnalytics.success(projectId))
  } catch (err) {
    yield put(actions.disableGoogleAnalytics.failure(projectId, err))
  }
}

export function* assignYandexMetrikaCounter({ projectId, tokenOwnerId, counterId }) {
  try {
    yield call(yandexMetrikaApi.assignCounter, projectId, tokenOwnerId, counterId)
    yield put(actions.assignYandexMetrikaCounter.success(projectId, tokenOwnerId, counterId))
  } catch (err) {
    yield put(actions.assignYandexMetrikaCounter.failure(projectId, err))
  }
}

function* authorizationRequest(projectId, service, connectParams) {
  const callbackUrl = `${window.location.origin}/callback.html`
  let connectUrl = `/connect/${service}?project=${projectId}&r=${callbackUrl}`

  if (connectParams) {
    Object.entries(connectParams).forEach(([key, value]) => {
      connectUrl += `&${key}=${value}`
    })
  }

  const winDialog = openDialog(
    absoluteUrl(connectUrl),
    service,
    DIALOG_WINDOW_WIDTH,
    DIALOG_WINDOW_HEIGHT
  )

  while (!winDialog.closed) {
    yield delay(300)
  }

  const key = `${service}:conn_status`
  const connStatus = localStorage.getItem(key)
  localStorage.removeItem(key)

  return connStatus || integrations.ERROR_ACCESS_DENIED
}

export function* connectService({ projectId, service, reconnect, params }) {
  try {
    let project = yield select(selectors.getProject, projectId)
    let serviceStatus = reconnect
      ? integrations.STATUS_UNCONNECTED
      : resolveIntegrationStatus(project, service, params)

    if (!serviceStatus) {
      throw new Error(`Could not resolve status for service: ${service}`)
    }

    if (serviceStatus === integrations.STATUS_DISABLED) {
      yield call(enableIntegration, projectId, service, params)
      const payload = yield call(api.fetchProjectDetails, projectId)

      project = extractProjectFromPayload(projectId, payload)
      serviceStatus = resolveIntegrationStatus(project, service, params)

      if (serviceStatus !== integrations.STATUS_REVOKED) {
        yield put(actions.enableIntegration.success(projectId, service, params, null))
        yield put(actions.fetchProjectDetails.success(projectId, payload))
        return
      }
    }

    const connectParams = {}

    if (service === integrations.SERVICE_YANDEX_METRIKA && params.counterId) {
      connectParams.counter = params.counterId
    }

    const connStatus = yield call(authorizationRequest, projectId, service, connectParams)
    if (connStatus === integrations.CONNECTION_SUCCESS) {
      yield put(actions.fetchProjectDetails(projectId, true))
    }

    yield put(
      actions.enableIntegration.success(
        projectId,
        service,
        params,
        connStatus === integrations.CONNECTION_SUCCESS ? null : connStatus
      )
    )
  } catch (err) {
    yield put(actions.enableIntegration.failure(projectId, service, params, err))
  }
}

function* watchApplyArchivedStateFilter() {
  yield takeLatest(types.SET_ARCHIVED_STATE, applyArchivedStateFilter)
}

function* watchLoadProjects() {
  yield takeEvery(types.FETCH_LIST, loadProjects)
}

function* watchLoadProjectDetails() {
  yield takeEvery(types.FETCH_DETAILS, loadProjectDetails)
}

function* watchCreateProject() {
  yield takeLatest(types.CREATE_REQUEST, createProject)
}

function* watchUpdateProject() {
  yield takeLatest(types.UPDATE_REQUEST, updateProject)
}

function* watchArchiveProject() {
  yield takeEvery(types.ARCHIVE_REQUEST, archiveProject)
}

function* watchUnarchiveProject() {
  yield takeEvery(types.UNARCHIVE_REQUEST, unarchiveProject)
}

function* watchDisalbeYandexDirect() {
  yield takeEvery(types.DISABLE_YANDEX_DIRECT_REQUEST, disableYandexDirect)
}

function* watchDisableYandexMetrika() {
  yield takeEvery(types.DISABLE_YANDEX_METRIKA_REQUEST, disableYandexMetrika)
}

function* watchAssignYandexMetrikaCounter() {
  yield takeLatest(types.ASSIGN_YANDEX_METRIKA_COUNTER_REQUEST, assignYandexMetrikaCounter)
}

function* watchDisalbeGoogleAnalytics() {
  yield takeEvery(types.DISABLE_GOOGLE_ANALYTICS_REQUEST, disableGoogleAnalytics)
}

function* watchEnableIntegarion() {
  yield takeLatest(types.ENABLE_INTEGRATION, connectService)
}

export function* projectsSaga() {
  yield all([
    watchApplyArchivedStateFilter(),
    watchLoadProjects(),
    watchLoadProjectDetails(),
    watchCreateProject(),
    watchUpdateProject(),
    watchArchiveProject(),
    watchUnarchiveProject(),
    watchDisalbeGoogleAnalytics(),
    watchDisalbeYandexDirect(),
    watchDisableYandexMetrika(),
    watchAssignYandexMetrikaCounter(),
    watchEnableIntegarion(),
  ])
}
