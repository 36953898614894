import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Sidebar } from 'semantic-ui-react'
import { getMenuItems } from '../constants/project-menus'
import isGranted from '../hoc/isGranted'

const items = getMenuItems().map((item) => {
  const MenuItem = ({ section, onItemClick }) => (
    <Menu.Item
      name={item.section}
      active={section === item.section}
      onClick={() => onItemClick(item.section)}
    >
      {item.title}
    </Menu.Item>
  )

  MenuItem.key = item.section
  MenuItem.propTypes = {
    section: PropTypes.string.isRequired,
    onItemClick: PropTypes.func.isRequired,
  }

  if (item.permissions) {
    return isGranted(item.permissions)(MenuItem)
  }

  return MenuItem
})

const ProjectSidebar = ({ projectId, currentSection, onItemClick }) => (
  <Sidebar as={Menu} visible vertical inverted>
    {items.map((Item) => (
      <Item
        key={Item.key}
        projectId={projectId}
        section={currentSection}
        onItemClick={onItemClick}
      />
    ))}
  </Sidebar>
)

ProjectSidebar.propTypes = {
  projectId: PropTypes.string.isRequired,
  currentSection: PropTypes.string.isRequired,
  onItemClick: PropTypes.func.isRequired,
}

export default ProjectSidebar
