import React from 'react'
import PropTypes from 'prop-types'
import { Card, Icon } from 'semantic-ui-react'

const Services = ({ projectId }) => (
  <Card.Group centered>
    <Card
      color="purple"
      href={`https://url-checker.airmediapro.ru/${projectId}/`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="heartbeat" size="massive" color="purple" style={{ margin: '0 auto' }} />
      <Card.Content textAlign="center">
        <Card.Header>Проверка ссылок</Card.Header>
        <Card.Description>
          Сервис позволяет проверить работоспособность ссылок в рекламных объявлениях.
        </Card.Description>
      </Card.Content>
    </Card>
    <Card
      color="red"
      href={`https://minusator.airmediapro.ru/${projectId}/`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="adjust" size="massive" color="red" style={{ margin: '0 auto' }} />
      <Card.Content textAlign="center">
        <Card.Header>Корректировка минус-фраз</Card.Header>
        <Card.Description>
          Сервис позволяет добавить или удалить фразы в единые списки минус-фраз для набора
          рекламных кампаний.
        </Card.Description>
      </Card.Content>
    </Card>
    <Card
      color="yellow"
      href={`https://reshuffler.airmediapro.ru/${projectId}/`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="recycle" size="massive" color="yellow" style={{ margin: '0 auto' }} />
      <Card.Content textAlign="center">
        <Card.Header>Перегруппировка</Card.Header>
        <Card.Description>
          Сервис позволяет перераспределить ключевые фразы из групп объявлений со статусом «мало
          показов».
        </Card.Description>
      </Card.Content>
    </Card>
    <Card
      color="blue"
      href="https://keywords-buncher.airmediapro.ru/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="object group" size="massive" color="blue" style={{ margin: '0 auto' }} />
      <Card.Content textAlign="center">
        <Card.Header>Группировка ключевых фраз</Card.Header>
        <Card.Description>
          Сервис позволяет объединить ключевые фразы в семантические группы.
        </Card.Description>
      </Card.Content>
    </Card>
    <Card
      color="pink"
      href={`https://cost-importer.airmediapro.ru/${projectId}/`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="share" size="massive" color="pink" style={{ margin: '0 auto' }} />
      <Card.Content textAlign="center">
        <Card.Header>Импорт расходов</Card.Header>
        <Card.Description>
          Сервис позволяет импортировать расходы в Google Analytics из Яндекс.Директа.
        </Card.Description>
      </Card.Content>
    </Card>
    <Card
      color="violet"
      href={`https://cost-optimizer.airmediapro.ru/${projectId}/`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="balance" size="massive" color="violet" style={{ margin: '0 auto' }} />
      <Card.Content textAlign="center">
        <Card.Header>Оптимизатор</Card.Header>
        <Card.Description>
          Сервис позволяет оптимизировать расход, посредством управления ставками и корректировками.
        </Card.Description>
      </Card.Content>
    </Card>
    <Card
      color="orange"
      href={`https://wordstat.airmediapro.ru/p/${projectId}/`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="signal" size="massive" color="orange" style={{ margin: '0 auto' }} />
      <Card.Content textAlign="center">
        <Card.Header>Парсер wordstat</Card.Header>
        <Card.Description>
          Сервис позволяет подобрать уточняющие фразы или собрать частотности для указанных фраз.
        </Card.Description>
      </Card.Content>
    </Card>
  </Card.Group>
)

Services.propTypes = {
  projectId: PropTypes.string.isRequired,
}

export default Services
