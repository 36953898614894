import React from 'react'
import PropTypes from 'prop-types'
import { Loader } from 'semantic-ui-react'

const Spinner = ({ inline }) => {
  const props = {
    active: true,
    size: 'large',
    content: 'Пожалуйста, подождите...',
  }
  if (inline) {
    props.inline = 'centered'
  }

  return <Loader {...props} />
}

Spinner.defaultProps = {
  inline: false,
}

Spinner.propTypes = {
  inline: PropTypes.bool,
}

export default Spinner
