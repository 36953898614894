import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import * as types from './constants'
import * as utils from '../utils'

export * from './actions'
export * from './constants'
export * from './selectors'

export default combineReducers({
  profile: handleActions(
    {
      [types.FETCH_PROFILE_SUCCESS]: (state, action) => action.payload,
      [types.LOGOUT]: () => {},
    },
    {}
  ),
  isFetching: handleActions(
    {
      [types.FETCH_PROFILE_REQUEST]: utils.setTrue,
      [types.FETCH_PROFILE_SUCCESS]: utils.setFalse,
      [types.FETCH_PROFILE_FAILURE]: utils.setFalse,
    },
    false
  ),
})
