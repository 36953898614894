import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Header, Segment } from 'semantic-ui-react'
import * as roles from '../constants/user-roles'
import EditProjectForm from './forms/EditProjectForm'
import { updateProject } from '../stores/projects'

const mapDispatchToProps = {
  updateProject,
}

const EditProjectSegment = ({ project, updateProject }) => {
  const { id: projectId, name: currentName, userRole } = project
  // Use local flag to avoid show spinner in all segments on page
  const [isSubmitting, setSubmitting] = React.useState(false)
  const handleSubmit = React.useCallback(
    (data) => {
      if (data.name === currentName) {
        return
      }

      setSubmitting(true)
      updateProject(projectId, {
        name: data.name,
      })
    },
    [projectId, currentName, updateProject]
  )

  if (userRole !== roles.OWNER && userRole !== roles.ADMIN) {
    return null
  }

  // Reset local state
  if (isSubmitting && !project.isUpdating) {
    setSubmitting(false)
  }

  return (
    <Segment>
      <Header>Редактирование</Header>
      <EditProjectForm
        initialValues={{
          name: currentName,
        }}
        isSubmitting={isSubmitting && project.isUpdating}
        onSubmit={handleSubmit}
      />
    </Segment>
  )
}

EditProjectSegment.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
    isUpdating: PropTypes.bool,
  }).isRequired,
  updateProject: PropTypes.func.isRequired,
}

export default connect(null, mapDispatchToProps)(EditProjectSegment)
