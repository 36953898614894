import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'semantic-ui-react'
import ProjectsListItem from './ProjectsListItem'

const ProjectsList = ({ projects, unarchiveProject }) => (
  <List selection>
    {projects.map((item) => (
      <ProjectsListItem key={item.id} item={item} unarchive={unarchiveProject} />
    ))}
  </List>
)

ProjectsList.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      archived: PropTypes.bool.isRequired,
      createdAt: PropTypes.string.isRequired,
    })
  ).isRequired,
  unarchiveProject: PropTypes.func.isRequired,
}

export default ProjectsList
