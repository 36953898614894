import { connect } from 'react-redux'
import { getArchivedStateFilter, getTotal, isFetching, setArchivedState } from '../stores/projects'
import IndexPage from '../components/pages/IndexPage'

const mapStateToProps = (state) => ({
  isFetching: isFetching(state),
  archivedState: getArchivedStateFilter(state),
  total: getTotal(state),
})

export default connect(mapStateToProps, { setArchivedState })(IndexPage)
