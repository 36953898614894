import {
  FETCH_LIST_FAILURE as FETCH_PROJECTS_LIST_FAILURE,
  FETCH_DETAILS_FAILURE as FETCH_PROJECT_DETAILS_FAILURE,
  CREATE_FAILURE as CREATE_PROJECT_FAILURE,
  UPDATE_FAILURE as UPDATE_PROJECT_FAILURE,
} from '../projects'
import * as notificationTypes from '../../constants/notification-types'
import createNotification from './utils/createNotification'
import getNotificationHeader from './utils/getNotificationHeader'
import getNotificationError from './utils/getNotificationError'
import * as types from './constants'

export * from './actions'
export * from './constants'

const setupErrorNotification = (state, actionType, status) => [
  ...state,
  createNotification({
    notificationType: notificationTypes.ERROR,
    header: getNotificationHeader(actionType),
    content: getNotificationError(status),
  }),
]

const notifications = (state = [], action) => {
  switch (action.type) {
    case types.ADD:
      return [...state, createNotification(action)]
    case types.REMOVE:
      return state.filter((notification) => notification.id !== action.id)
    case FETCH_PROJECTS_LIST_FAILURE:
    case FETCH_PROJECT_DETAILS_FAILURE:
    case CREATE_PROJECT_FAILURE:
    case UPDATE_PROJECT_FAILURE:
      if (action.error && action.error.status) {
        return setupErrorNotification(state, action.type, action.error.status)
      }
    // no default
  }
  return state
}

export default notifications
