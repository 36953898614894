import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as api from '@airmedia/auth'
import * as types from './constants'

export function* logout() {
  yield call(api.signOut)
}

export function* loadProfile() {
  try {
    const payload = yield call(api.fetchProfile)
    return yield put({ type: types.FETCH_PROFILE_SUCCESS, payload })
  } catch (error) {
    yield put({ type: types.FETCH_PROFILE_FAILURE, error })
  }

  return null
}

function* watchLoadProfile() {
  yield takeLatest(types.FETCH_PROFILE_REQUEST, loadProfile)
}

function* watchLogout() {
  yield takeLatest(types.LOGOUT, logout)
}

export function* userSaga() {
  yield all([watchLoadProfile(), watchLogout()])
}
