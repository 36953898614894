import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import IntegrationYandexDirect from '../components/IntegrationYandexDirect'
import {
  disableYandexDirect,
  enableIntegration,
  getProject,
  updateProject,
} from '../stores/projects'
import * as integration from '../constants/integrations'

const mapStateToProps = (state, { projectId }) => {
  return {
    projectId,
    project: getProject(state, projectId) || {},
  }
}

class IntegrationYandexDirectContainer extends Component {
  handleConnect = (reconnect = false) => {
    this.props.enableIntegration(this.props.projectId, integration.SERVICE_YANDEX_DIRECT, reconnect)
  }

  handleDisable = () => {
    this.props.disableYandexDirect(this.props.projectId)
  }

  onSetClient = (clientLogin) => {
    const { projectId, updateProject } = this.props
    updateProject(projectId, {
      yandexDirectClientLogin: clientLogin,
    })
  }

  render() {
    const { projectId, project } = this.props

    return (
      <IntegrationYandexDirect
        projectId={projectId}
        authLogin={project.yandexDirectAuthLogin}
        clientLogin={project.yandexDirectClientLogin}
        status={project.yandexDirectStatus}
        isConnecting={project.yandexDirectIsUpdating}
        isUpdating={project.isUpdating}
        connectionError={project.yandexDirectConnError}
        onConnect={this.handleConnect}
        onDisable={this.handleDisable}
        setClient={this.onSetClient}
      />
    )
  }
}

IntegrationYandexDirectContainer.defaultProps = {
  project: null,
}

IntegrationYandexDirectContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    yandexDirectStatus: PropTypes.string.isRequired,
    yandexDirectAuthLogin: PropTypes.string,
    yandexDirectClientLogin: PropTypes.string,
    yandexDirectIsUpdating: PropTypes.bool,
    yandexDirectConnError: PropTypes.string,
    isUpdating: PropTypes.bool,
  }),
  enableIntegration: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  disableYandexDirect: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {
  enableIntegration,
  disableYandexDirect,
  updateProject,
})(IntegrationYandexDirectContainer)
