// semantic-ui colors
export const COLOR_RED = '#db2828'
export const COLOR_ORANGE = '#f2711c'
export const COLOR_YELLOW = '#fbbd08'
export const COLOR_OLIVE = '#b5cc18'
export const COLOR_GREEN = '#21ba45'
export const COLOR_TEAL = '#00b5ad'
export const COLOR_BLUE = '#2185d0'
export const COLOR_VIOLET = '#6435c9'
export const COLOR_PURPLE = '#a333c8'
export const COLOR_PINK = '#e03997'
export const COLOR_BROWN = '#a5673f'
export const COLOR_GREY = '#838383'
export const COLOR_BLACK = '#1b1c1d'

export const TEXT_COLOR_DARK = 'rgba(0, 0, 0, 0.85)'
export const TEXT_COLOR_LIGHT = 'rgba(0, 0, 0, 0.4)'
export const TEXT_COLOR_MUTED = 'rgba(0, 0, 0, 0.6)'

export const SIZE_MINI = 11 / 14
export const SIZE_TINY = 12 / 14
export const SIZE_SMALL = 13 / 14
export const SIZE_MEDIUM = 14 / 14
export const SIZE_LARGE = 16 / 14
export const SIZE_BIG = 18 / 14
export const SIZE_HUGE = 20 / 14
export const SIZE_MASSIVE = 24 / 14
