import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const ErrorMessageWrap = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: #db2828;
  transform: translateY(-0.7em);
`
/* eslint-disable react/no-array-index-key */
const ErrorMessage = ({ error, ...rest }) => {
  if (Array.isArray(error)) {
    return error.map((text, idx) => (
      <ErrorMessageWrap key={idx} {...rest}>
        {text}
      </ErrorMessageWrap>
    ))
  }
  return <ErrorMessageWrap {...rest}>{error}</ErrorMessageWrap>
}

ErrorMessage.defaultProps = {
  error: null,
}

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
}

export default ErrorMessage
