import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Breadcrumb, Icon } from 'semantic-ui-react'

/* eslint-disable react/no-array-index-key */
const Breadcrumbs = ({ className, items }) => (
  <Breadcrumb className={className}>
    {items.map((item, idx, arr) => {
      if (idx === arr.length - 1) {
        return (
          <Breadcrumb.Section
            key={idx}
            className="breadcrumb-item--active"
            active
            content={item.content}
          />
        )
      }
      return (
        <Fragment key={idx}>
          <Breadcrumb.Section className="breadcrumb-item">
            <Link to={item.link} className="no-decoration">
              {item.content}
            </Link>
          </Breadcrumb.Section>
          <Breadcrumb.Divider>
            <Icon name="right chevron" inverted />
          </Breadcrumb.Divider>
        </Fragment>
      )
    })}
  </Breadcrumb>
)
/* eslint-enable react/no-array-index-key */

const StyledBreadcrumbs = styled(Breadcrumbs)`
  display: flex !important;
  align-items: baseline;

  .breadcrumb-item a,
  .breadcrumb-item--active {
    display: block;
    max-width: 20vw;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    margin-right: -5px;
  }
`

Breadcrumbs.propTypes = {
  className: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ).isRequired,
}

StyledBreadcrumbs.WrappedComponent = Breadcrumbs

export default StyledBreadcrumbs
