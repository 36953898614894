import { createSelector } from 'reselect'

const getMembersById = (state) => state.members.byId
const getMembersIds = (state) => state.members.ids

export const isMembersFetching = (state) => state.members.isFetching
export const isMemberAdding = (state) => state.members.isAdding

export const getMembers = createSelector(getMembersById, getMembersIds, (members, ids) =>
  ids.map((id) => members[id])
)

export const getMember = createSelector(
  getMembersById,
  (state, memberId) => memberId,
  (members, id) => members[id]
)

const getMemberProperty = (state, memberId, property) => {
  const member = getMember(state, memberId)

  if (member) {
    return member[property]
  }

  return null
}

export const isMemberRemoving = (state, memberId) =>
  getMemberProperty(state, memberId, 'isRemoving')
