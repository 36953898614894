import { callApi } from '@airmedia/api-utils'
import { resolveAccessToken } from './accessToken'

export default (method, endpoint, options = {}) => {
  if (options.token !== false && !options.token) {
    return callApi(method, endpoint, {
      ...options,
      token: resolveAccessToken,
    })
  }
  return callApi(method, endpoint, options)
}
