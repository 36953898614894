import React from 'react'
import PropTypes from 'prop-types'
import Spinner from './Spinner'
import ArchiveProjectSegment from './settings/ArchiveProjectSegment'
import EditProjectSegment from '../containers/EditProjectSegment'

const shouldDisplayLoader = (project) =>
  !project || (project.isFetching && project.userRole === undefined)

const Settings = ({ project, onMoveToArchive }) => {
  if (shouldDisplayLoader(project)) {
    return <Spinner />
  }

  return (
    <>
      <EditProjectSegment project={project} />
      <ArchiveProjectSegment project={project} onMoveToArchive={onMoveToArchive} />
    </>
  )
}

Settings.defaultProps = {
  project: null,
}

Settings.propTypes = {
  project: PropTypes.shape({
    isFetching: PropTypes.bool,
    userRole: PropTypes.string,
  }),
  onMoveToArchive: PropTypes.func.isRequired,
}

export default Settings
