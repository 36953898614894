import * as types from './constants'

export const logout = () => ({
  type: types.LOGOUT,
})

export const fetchProfile = () => ({
  type: types.FETCH_PROFILE_REQUEST,
})

fetchProfile.success = (payload) => ({
  payload,
  type: types.FETCH_PROFILE_SUCCESS,
})

fetchProfile.failure = (error) => ({
  error,
  type: types.FETCH_PROFILE_FAILURE,
})
