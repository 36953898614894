import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './rootReducer'
import rootSaga from './rootSaga'
import { rollbarMiddleware } from '../rollbar'

const configureStore = (preloadedState) => {
  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(sagaMiddleware, rollbarMiddleware)
  )

  sagaMiddleware.run(rootSaga)

  return store
}
export default configureStore
