export const SERVICE_YANDEX_DIRECT = 'yandex-direct'
export const SERVICE_YANDEX_METRIKA = 'yandex-metrika'
export const SERVICE_GOOGLE_ANALYTICS = 'google-analytics'

export const STATUS_UNCONNECTED = 'unconnected'
export const STATUS_CONNECTED = 'connected'
export const STATUS_REVOKED = 'revoked'
export const STATUS_PENDING = 'pending'
export const STATUS_DISABLED = 'disabled'

export const CONNECTION_SUCCESS = 'connection_success'

export const ERROR_ACCESS_DENIED = 'access_denied'
export const ERROR_FORBIDDEN = 'forbidden'
export const ERROR_BAD_REQUEST = 'bad_request'
export const ERROR_CONFLICT = 'conflict'
