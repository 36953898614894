import React, { Component } from 'react'
import { Container, Header } from 'semantic-ui-react'
import rollbar from '../rollbar'

/* eslint-disable react/prop-types */
class ErrorHandlerLocal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
    }
  }

  componentDidCatch(error) {
    this.setState({
      error,
    })

    rollbar.error('Error while rendering components', error)
  }

  render() {
    if (this.state.error) {
      return (
        <Container textAlign="center">
          <Header color="red">Oops!</Header>
          <p>Мы пытались нарисовать красивую страницу, но что-то пошло не так.</p>
          <p>При повторении ошибки, пожалуйста, обратитесь за помощью к администратору сервиса.</p>
        </Container>
      )
    }

    return this.props.children
  }
}

export default ErrorHandlerLocal
