import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import IntegraionGoogleAnalytics from '../components/IntegrationGoogleAnalytics'
import {
  disableGoogleAnalytics,
  enableIntegration,
  getProject,
  updateProject,
} from '../stores/projects'
import * as integration from '../constants/integrations'

const mapStateToProps = (state, { projectId }) => {
  return {
    projectId,
    project: getProject(state, projectId) || {},
  }
}

class IntegrationGoogleAnalyticsContainer extends Component {
  handleConnect = () => {
    const { projectId, enableIntegration } = this.props
    enableIntegration(projectId, integration.SERVICE_GOOGLE_ANALYTICS)
  }

  handleDisable = () => {
    const { projectId, disableGoogleAnalytics } = this.props
    disableGoogleAnalytics(projectId)
  }

  onSetWebPropertyId = (accountId, webPropertyId) => {
    const { projectId, updateProject } = this.props
    updateProject(projectId, {
      googleAnalyticsAccountId: accountId,
      googleAnalyticsWebPropertyId: webPropertyId,
    })
  }

  render() {
    const { projectId, project } = this.props

    return (
      <IntegraionGoogleAnalytics
        projectId={projectId}
        authLogin={project.googleAnalyticsAuthLogin}
        webPropertyId={project.googleAnalyticsWebPropertyId}
        status={project.googleAnalyticsStatus}
        isConnecting={project.googleAnalyticsIsUpdating || false}
        isUpdating={project.isUpdating}
        connectionError={project.googleAnalyticsConnError}
        onConnect={this.handleConnect}
        onDisable={this.handleDisable}
        setWebProperty={this.onSetWebPropertyId}
      />
    )
  }
}

IntegrationGoogleAnalyticsContainer.defaultProps = {
  project: null,
}

IntegrationGoogleAnalyticsContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    googleAnalyticsStatus: PropTypes.string.isRequired,
    googleAnalyticsAuthLogin: PropTypes.string,
    googleAnalyticsWebPropertyId: PropTypes.string,
    googleAnalyticsIsUpdating: PropTypes.bool,
    googleAnalyticsConnError: PropTypes.string,
    isUpdating: PropTypes.bool,
  }),
  updateProject: PropTypes.func.isRequired,
  disableGoogleAnalytics: PropTypes.func.isRequired,
  enableIntegration: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {
  enableIntegration,
  disableGoogleAnalytics,
  updateProject,
})(IntegrationGoogleAnalyticsContainer)
