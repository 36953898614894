import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { generatePath } from 'react-router'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AuthRoute, withAuth } from '@airmedia/auth'
import GlobalStyles from '../styles'
import ErrorHandler from '../containers/ErrorHandler'
import IndexPage from '../containers/ProjectsListPage'
import ProjectPage from '../containers/ProjectPage'
import NewProjectPage from '../containers/NewProjectPage'
import Page404 from './pages/Page404'
import Header from './Header'
import NotificationEmitter from '../containers/NotificationEmitter'
import * as routes from '../constants/routes'
import { SERVICES } from '../constants/project-menus'

const DefaultLayout = ({ component: Component, ...props }) => (
  <Fragment>
    <Header {...props} />
    <ErrorHandler>
      <Component {...props} />
    </ErrorHandler>
  </Fragment>
)

DefaultLayout.propTypes = {
  component: PropTypes.func.isRequired,
}

const RouteWithLayout = ({ component, ...props }) => (
  <Route {...props} render={(props) => <DefaultLayout component={component} {...props} />} />
)

RouteWithLayout.propTypes = {
  component: PropTypes.func.isRequired,
}

const IndexPageWithAuth = withAuth(IndexPage)
const ProjectPageWithAuth = withAuth(ProjectPage)
const NewProjectPageWithAuth = withAuth(NewProjectPage)

export default () => (
  <ErrorHandler>
    <GlobalStyles />
    <Switch>
      <AuthRoute path={routes.AUTH_PAGE} />
      <Route
        exact
        path={routes.PROJECT_INDEX_PAGE}
        render={({ match }) => (
          <Redirect
            to={generatePath(routes.PROJECT_PAGE, { ...match.params, section: SERVICES })}
          />
        )}
      />
      <RouteWithLayout exact path={routes.INDEX_PAGE} component={IndexPageWithAuth} />
      <RouteWithLayout exact path={routes.PROJECT_PAGE} component={ProjectPageWithAuth} />
      <RouteWithLayout exact path={routes.NEW_PROJECT_PAGE} component={NewProjectPageWithAuth} />
      <Route component={Page404} />
    </Switch>
    <NotificationEmitter />
  </ErrorHandler>
)
