import { camelizeKeys, decamelizeKeys } from 'humps'
import { schema } from 'normalizr'
import callApi from './callApi'

const memberSchema = new schema.Entity(
  'members',
  {},
  {
    processStrategy: (entity) => camelizeKeys(entity),
  }
)

export async function fetchMembers(projectId) {
  return callApi('GET', `/api/projects/${projectId}/members`, {
    schema: [memberSchema],
  })
}

export async function addMember(projectId, member) {
  return callApi('POST', `/api/projects/${projectId}/members`, {
    data: decamelizeKeys(member),
    schema: memberSchema,
  })
}

export async function deleteMember(projectId, memberId) {
  return callApi('DELETE', `/api/projects/${projectId}/members/${memberId}`)
}
