import { camelizeKeys } from 'humps'
import callApi from './callApi'

export async function fetchCounters(projectId, tokenOwnerId) {
  const payload = await callApi(
    'GET',
    `/api/projects/${projectId}/yandex-metrika/${tokenOwnerId}/counters`
  )

  return payload.data.map((item) => camelizeKeys(item))
}

export async function assignCounter(projectId, tokenOwnerId, counterId) {
  return callApi('PUT', `/api/projects/${projectId}/yandex-metrika`, {
    data: {
      token_owner_id: tokenOwnerId,
      counter_id: counterId,
    },
  })
}

export async function disable(projectId, counterId) {
  return callApi('PUT', `/api/projects/${projectId}/yandex-metrika/${counterId}/disable`)
}

export async function enable(projectId, counterId) {
  return callApi('DELETE', `/api/projects/${projectId}/yandex-metrika/${counterId}/disable`)
}
