import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import IntegrationYandexMetrika from '../components/IntegrationYandexMetrika'
import {
  disableYandexMetrika,
  enableIntegration,
  getProject,
  assignYandexMetrikaCounter,
} from '../stores/projects'
import * as integration from '../constants/integrations'

const mapStateToProps = (state, { projectId }) => {
  return {
    projectId,
    project: getProject(state, projectId) || {},
  }
}

class IntegrationYandexMetrikaContainer extends Component {
  handleConnect = (counterId, reconnect = false) => {
    this.props.enableIntegration(
      this.props.projectId,
      integration.SERVICE_YANDEX_METRIKA,
      reconnect,
      {
        counterId,
      }
    )
  }

  handleDisable = (counterId) => {
    this.props.disableYandexMetrika(this.props.projectId, counterId)
  }

  handleSelectCounter = (tokenOwnerId, counterId) => {
    this.props.assignCounter(this.props.projectId, tokenOwnerId, counterId)
  }

  render() {
    const { projectId, project } = this.props

    if (project.yandexMetrika === undefined || project.yandexMetrika === null) {
      return null
    }

    return (
      <IntegrationYandexMetrika
        projectId={projectId}
        integrations={project.yandexMetrika}
        connectionError={project.yandexMetrikaConnError}
        isConnecting={project.yandexMetrikaIsUpdating || false}
        onConnect={this.handleConnect}
        onDisable={this.handleDisable}
        onSelectCounter={this.handleSelectCounter}
      />
    )
  }
}

IntegrationYandexMetrikaContainer.defaultProps = {
  project: null,
}

IntegrationYandexMetrikaContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    yandexMetrika: PropTypes.arrayOf(
      PropTypes.shape({
        tokenOwnerId: PropTypes.string.isRequired,
        tokenOwnerLogin: PropTypes.string.isRequired,
        counterId: PropTypes.number,
        status: PropTypes.string.isRequired,
      })
    ),
    yandexMetrikaConnError: PropTypes.string,
    yandexMetrikaIsUpdating: PropTypes.bool,
  }),
  enableIntegration: PropTypes.func.isRequired,
  assignCounter: PropTypes.func.isRequired,
  disableYandexMetrika: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {
  enableIntegration,
  disableYandexMetrika,
  assignCounter: assignYandexMetrikaCounter,
})(IntegrationYandexMetrikaContainer)
