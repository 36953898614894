import uniq from 'lodash/uniq'

const updateEntity = (state, id, data) => ({
  ...state,
  [id]: {
    ...state[id],
    ...data,
  },
})

export const mergeIds = (state, action) => uniq(state.concat(action.result))

export const makeMergeEntities = (entityType) => (state, action) => ({
  ...state,
  ...action.entities[entityType],
})
export const makeUpdateEntity =
  (idResolver, data, updater = updateEntity) =>
  (state, action) => {
    if (typeof data === 'function') {
      return updater(state, idResolver(action), data(action))
    }
    return updater(state, idResolver(action), data)
  }

export const setTrue = () => true
export const setFalse = () => false
