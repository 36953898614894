import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import NewProjectPage from '../components/pages/NewProjectPage'
import { createProject, isCreating } from '../stores/projects'
import * as projectMenus from '../constants/project-menus'

const mapStateToProps = (state) => ({
  isCreating: isCreating(state),
})

const NewProjectPageContainer = ({ history, createProject, isCreating }) => {
  const createNewProject = (project) => {
    createProject(project, (projectId) => {
      history.push(`/${projectId}/${projectMenus.SERVICES}/`)
    })
  }

  return <NewProjectPage createProject={createNewProject} isCreating={isCreating} />
}

NewProjectPageContainer.propTypes = {
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  createProject: PropTypes.func.isRequired,
  isCreating: PropTypes.bool.isRequired,
}

export default withRouter(connect(mapStateToProps, { createProject })(NewProjectPageContainer))
