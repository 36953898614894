import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'semantic-ui-react'

const DropdownReduxForm = ({ input, ...rest }) => (
  <Dropdown
    onFocus={input.onFocus}
    onBlur={input.onBlur}
    onChange={(e, { value }) => {
      input.onChange(value)
    }}
    {...rest}
  />
)

DropdownReduxForm.propTypes = {
  input: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default DropdownReduxForm
