import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import { omit } from 'lodash'
import { camelize } from 'humps'
import * as integrations from '../../constants/integrations'
import * as types from './constants'
import * as utils from '../utils'
import { ARCHIVED_STATE_NOT_ARCHIVED } from '../../api/projects'

export * from './actions'
export * from './constants'
export * from './selectors'

const mergeProjects = utils.makeMergeEntities('projects')
const makeUpdateProject = (data) => (state, action) => {
  const project = state[action.projectId]
  return {
    ...state,
    [action.projectId]: {
      ...project,
      ...(typeof data === 'function' ? data(action, project) : data),
    },
  }
}
const updateYandexMetrikaIntegrations = (action, integrations, props, predicate) =>
  integrations.map((item) => {
    if (predicate(item, action)) {
      return {
        ...item,
        ...(typeof props === 'function' ? props(action) : props),
      }
    }

    return item
  })
const unsetProjectFetching = makeUpdateProject({ isFetching: false })
const unsetProjectUpdating = makeUpdateProject({ isUpdating: false })
const setProjectUpdating = makeUpdateProject({ isUpdating: true })
const markProjectAsArchived = makeUpdateProject({ archived: true })
const markProjectAsActive = makeUpdateProject({ archived: false })
const setYandexDirectUpdating = makeUpdateProject({ yandexDirectIsUpdating: true })
const unsetYandexDirectUpdating = makeUpdateProject({ yandexDirectIsUpdating: false })
const setGoogleAnalyticsUpdating = makeUpdateProject({ googleAnalyticsIsUpdating: true })
const unsetGoogleAnalyticsUpdating = makeUpdateProject({ googleAnalyticsIsUpdating: false })
const makeUpdateYandexMetrikaIntegration = (props, predicate) =>
  makeUpdateProject((action, project) => ({
    yandexMetrika: updateYandexMetrikaIntegrations(action, project.yandexMetrika, props, predicate),
  }))

const removeIdByFlag = (state, action) => {
  if (action.shouldDelete) {
    return state.filter((id) => id !== action.projectId)
  }
  return state
}

const initialErrorState = {
  errCode: null,
  requestFailed: false,
  requestId: null,
}

export default combineReducers({
  byId: handleActions(
    {
      [types.FETCH_LIST_SUCCESS]: mergeProjects,
      [types.FETCH_DETAILS_REQUEST]: makeUpdateProject({ isFetching: true }),
      [types.FETCH_DETAILS_SUCCESS]: (state, action) =>
        unsetProjectFetching(mergeProjects(state, action), action),
      [types.FETCH_DETAILS_FAILURE]: unsetProjectFetching,
      [types.CREATE_SUCCESS]: mergeProjects,
      [types.UPDATE_REQUEST]: setProjectUpdating,
      [types.UPDATE_SUCCESS]: (state, action) =>
        unsetProjectUpdating(mergeProjects(state, action), action),
      [types.UPDATE_FAILURE]: unsetProjectUpdating,
      [types.SET_ARCHIVED_STATE]: () => ({}),
      [types.ARCHIVE_REQUEST]: setProjectUpdating,
      [types.ARCHIVE_SUCCESS]: (state, action) => {
        if (action.shouldDelete) {
          return omit(state, action.projectId)
        }
        return markProjectAsArchived(unsetProjectUpdating(state, action), action)
      },
      [types.ARCHIVE_FAILURE]: unsetProjectUpdating,
      [types.UNARCHIVE_REQUEST]: setProjectUpdating,
      [types.UNARCHIVE_SUCCESS]: (state, action) => {
        if (action.shouldDelete) {
          return omit(state, action.projectId)
        }
        return markProjectAsActive(unsetProjectUpdating(state, action), action)
      },
      [types.UNARCHIVE_FAILURE]: unsetProjectUpdating,
      [types.DISABLE_YANDEX_DIRECT_REQUEST]: setYandexDirectUpdating,
      [types.DISABLE_YANDEX_DIRECT_SUCCESS]: makeUpdateProject({
        yandexDirectStatus: integrations.STATUS_DISABLED,
        yandexDirectIsUpdating: false,
      }),
      [types.DISABLE_YANDEX_DIRECT_FAILURE]: unsetYandexDirectUpdating,
      [types.ASSIGN_YANDEX_METRIKA_COUNTER_REQUEST]: makeUpdateYandexMetrikaIntegration(
        { isUpdating: true },
        (item, action) => item.tokenOwnerId === action.tokenOwnerId && !item.counterId
      ),
      [types.ASSIGN_YANDEX_METRIKA_COUNTER_SUCCESS]: makeUpdateYandexMetrikaIntegration(
        (action) => ({
          isUpdating: false,
          counterId: action.counterId,
          status: integrations.STATUS_CONNECTED,
        }),
        (item, action) => item.tokenOwnerId === action.tokenOwnerId && !item.counterId
      ),
      [types.ASSIGN_YANDEX_METRIKA_COUNTER_FAILURE]: makeUpdateYandexMetrikaIntegration(
        { isUpdating: false },
        (item, action) => item.tokenOwnerId === action.tokenOwnerId && !item.counterId
      ),
      [types.DISABLE_YANDEX_METRIKA_REQUEST]: makeUpdateYandexMetrikaIntegration(
        { isUpdating: true },
        (item, action) => item.counterId === action.counterId
      ),
      [types.DISABLE_YANDEX_METRIKA_SUCCESS]: makeUpdateYandexMetrikaIntegration(
        {
          status: integrations.STATUS_DISABLED,
          isUpdating: false,
        },
        (item, action) => item.counterId === action.counterId
      ),
      [types.DISABLE_YANDEX_METRIKA_FAILURE]: makeUpdateYandexMetrikaIntegration(
        { isUpdating: false },
        (item, action) => item.counterId === action.counterId
      ),
      [types.DISABLE_GOOGLE_ANALYTICS_REQUEST]: setGoogleAnalyticsUpdating,
      [types.DISABLE_GOOGLE_ANALYTICS_SUCCESS]: makeUpdateProject({
        googleAnalyticsStatus: integrations.STATUS_DISABLED,
        googleAnalyticsIsUpdating: false,
      }),
      [types.DISABLE_GOOGLE_ANALYTICS_FAILURE]: unsetGoogleAnalyticsUpdating,
      [types.ENABLE_INTEGRATION]: makeUpdateProject((action, project) => {
        if (
          action.service === integrations.SERVICE_YANDEX_METRIKA &&
          action.params &&
          action.params.counterId
        ) {
          return {
            yandexMetrika: updateYandexMetrikaIntegrations(
              action,
              project.yandexMetrika,
              { isUpdating: true },
              (item, action) => item.counterId && item.counterId === action.params.counterId
            ),
          }
        }

        const prefix = camelize(action.service)
        return {
          [`${prefix}IsUpdating`]: true,
        }
      }),
      [types.ENABLE_INTEGRATION_SUCCESS]: makeUpdateProject((action, project) => {
        if (
          action.service === integrations.SERVICE_YANDEX_METRIKA &&
          action.params &&
          action.params.counterId
        ) {
          return {
            yandexMetrika: updateYandexMetrikaIntegrations(
              action,
              project.yandexMetrika,
              {
                isUpdating: false,
                connectionError: action.connectionError,
              },
              (item, action) => item.counterId && item.counterId === action.params.counterId
            ),
          }
        }

        const prefix = camelize(action.service)
        return {
          [`${prefix}IsUpdating`]: false,
          [`${prefix}ConnError`]: action.connectionError,
        }
      }),
      [types.ENABLE_INTEGRATION_FAILURE]: makeUpdateProject((action, project) => {
        if (
          action.service === integrations.SERVICE_YANDEX_METRIKA &&
          action.params &&
          action.params.counterId
        ) {
          return {
            yandexMetrika: updateYandexMetrikaIntegrations(
              action,
              project.yandexMetrika,
              { isUpdating: false },
              (item, action) => item.counterId && item.counterId === action.params.counterId
            ),
          }
        }

        const prefix = camelize(action.service)
        return {
          [`${prefix}IsUpdating`]: false,
        }
      }),
    },
    {}
  ),
  ids: handleActions(
    {
      [types.FETCH_LIST_SUCCESS]: utils.mergeIds,
      [types.SET_ARCHIVED_STATE]: () => [],
      [types.ARCHIVE_SUCCESS]: removeIdByFlag,
      [types.UNARCHIVE_SUCCESS]: removeIdByFlag,
    },
    []
  ),
  isFetching: handleActions(
    {
      [types.FETCH_LIST_REQUEST]: utils.setTrue,
      [types.FETCH_LIST_SUCCESS]: utils.setFalse,
      [types.FETCH_LIST_FAILURE]: utils.setFalse,
    },
    false
  ),
  isCreating: handleActions(
    {
      [types.CREATE_REQUEST]: utils.setTrue,
      [types.CREATE_SUCCESS]: utils.setFalse,
      [types.CREATE_FAILURE]: utils.setFalse,
    },
    false
  ),
  error: handleActions(
    {
      [types.FETCH_LIST_SUCCESS]: () => initialErrorState,
      [types.FETCH_LIST_FAILURE]: (state, action) => ({
        errCode: (action.error && action.error.code) || null,
        requestFailed: true,
        requestId: action.requestId,
      }),
    },
    initialErrorState
  ),
  nextPageOffset: handleActions(
    {
      [types.FETCH_LIST_SUCCESS]: (state, action) => action.nextPageOffset,
      [types.SET_ARCHIVED_STATE]: () => 0,
    },
    0
  ),
  total: handleActions(
    {
      [types.FETCH_LIST_SUCCESS]: (state, action) => action.meta.total,
      [types.SET_ARCHIVED_STATE]: () => 0,
    },
    0
  ),
  archivedStateFilter: handleActions(
    {
      [types.SET_ARCHIVED_STATE]: (state, action) => action.archivedState,
    },
    ARCHIVED_STATE_NOT_ARCHIVED
  ),
})
