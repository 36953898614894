const prefix = '@projects/'

export const SET_ARCHIVED_STATE = `${prefix}SET_ARCHIVED_STATE`

export const FETCH_LIST = `${prefix}FETCH_LIST`
export const FETCH_LIST_REQUEST = `${prefix}FETCH_LIST_REQUEST`
export const FETCH_LIST_SUCCESS = `${prefix}FETCH_LIST_SUCCESS`
export const FETCH_LIST_FAILURE = `${prefix}FETCH_LIST_FAILURE`

export const FETCH_DETAILS = `${prefix}FETCH_DETAILS`
export const FETCH_DETAILS_REQUEST = `${prefix}FETCH_DETAILS_REQUEST`
export const FETCH_DETAILS_SUCCESS = `${prefix}FETCH_DETAILS_SUCCESS`
export const FETCH_DETAILS_FAILURE = `${prefix}FETCH_DETAILS_FAILURE`

export const CREATE_REQUEST = `${prefix}CREATE_REQUEST`
export const CREATE_SUCCESS = `${prefix}CREATE_SUCCESS`
export const CREATE_FAILURE = `${prefix}CREATE_FAILURE`

export const UPDATE_REQUEST = `${prefix}UPDATE_REQUEST`
export const UPDATE_SUCCESS = `${prefix}UPDATE_SUCCESS`
export const UPDATE_FAILURE = `${prefix}UPDATE_FAILURE`

export const ARCHIVE_REQUEST = `${prefix}ARCHIVE_REQUEST`
export const ARCHIVE_SUCCESS = `${prefix}ARCHIVE_SUCCESS`
export const ARCHIVE_FAILURE = `${prefix}ARCHIVE_FAILURE`

export const UNARCHIVE_REQUEST = `${prefix}UNARCHIVE_REQUEST`
export const UNARCHIVE_SUCCESS = `${prefix}UNARCHIVE_SUCCESS`
export const UNARCHIVE_FAILURE = `${prefix}UNARCHIVE_FAILURE`

export const DISABLE_YANDEX_DIRECT_REQUEST = `${prefix}DISABLE_YANDEX_DIRECT_REQUEST`
export const DISABLE_YANDEX_DIRECT_SUCCESS = `${prefix}DISABLE_YANDEX_DIRECT_SUCCESS`
export const DISABLE_YANDEX_DIRECT_FAILURE = `${prefix}DISABLE_YANDEX_DIRECT_FAILURE`

export const DISABLE_YANDEX_METRIKA_REQUEST = `${prefix}DISABLE_YANDEX_METRIKA_REQUEST`
export const DISABLE_YANDEX_METRIKA_SUCCESS = `${prefix}DISABLE_YANDEX_METRIKA_SUCCESS`
export const DISABLE_YANDEX_METRIKA_FAILURE = `${prefix}DISABLE_YANDEX_METRIKA_FAILURE`

export const DISABLE_GOOGLE_ANALYTICS_REQUEST = `${prefix}DISABLE_GOOGLE_ANALYTICS_REQUEST`
export const DISABLE_GOOGLE_ANALYTICS_SUCCESS = `${prefix}DISABLE_GOOGLE_ANALYTICS_SUCCESS`
export const DISABLE_GOOGLE_ANALYTICS_FAILURE = `${prefix}DISABLE_GOOGLE_ANALYTICS_FAILURE`

export const ASSIGN_YANDEX_METRIKA_COUNTER_REQUEST = `${prefix}ASSIGN_YANDEX_METRIKA_COUNTER_REQUEST`
export const ASSIGN_YANDEX_METRIKA_COUNTER_SUCCESS = `${prefix}ASSIGN_YANDEX_METRIKA_COUNTER_SUCCESS`
export const ASSIGN_YANDEX_METRIKA_COUNTER_FAILURE = `${prefix}ASSIGN_YANDEX_METRIKA_COUNTER_FAILURE`

export const ENABLE_INTEGRATION = `${prefix}ENABLE_INTEGRATION`
export const ENABLE_INTEGRATION_SUCCESS = `${prefix}ENABLE_INTEGRATION_SUCCESS`
export const ENABLE_INTEGRATION_FAILURE = `${prefix}ENABLE_INTEGRATION_FAILURE`
