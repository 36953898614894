export const OWNER = 'OWNER'
export const ADMIN = 'ADMIN'
export const CHIEF = 'CHIEF'
export const ASSISTANT = 'ASSISTANT'

export const getReadableRole = (role) => {
  switch (role) {
    case OWNER:
      return 'Владелец'
    case ADMIN:
      return 'Администратор'
    case CHIEF:
      return 'Руководитель отдела'
    case ASSISTANT:
      return 'Специалист'

    // no default
  }

  throw new Error(`Unexpected role ${role}`)
}

export const getRoles = () => [OWNER, ADMIN, CHIEF, ASSISTANT]
