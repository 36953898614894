import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Form, Button } from 'semantic-ui-react'
import { reduxForm, Field } from 'redux-form'
import { email } from '../../utils/validators'
import InputField from './InputField'
import DropdownReduxForm from './DropdownReduxForm'
import ErrorMessage from './ErrorMessage'
import { getReadableRole, getRoles } from '../../constants/user-roles'

const NewMemberFormWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;

  & > *:first-child {
    flex: 0 1 40%;
  }

  & > *:nth-child(2) {
    flex: 0 1 35%;
  }

  & > *:last-child {
    flex-basis: 160px;
    margin-right: 0 !important;
  }
`

const roles = getRoles()
const roleDropdownOptions = roles.map((role, idx) => ({
  key: idx,
  text: getReadableRole(role),
  value: role,
}))

const normalizeEmail = (value) => value && value.trim()

const NewMemberForm = ({ isSubmitting, handleSubmit, error }) => {
  const [canSubmit, setCanSubmit] = useState(false)
  const handleChangeRole = useCallback(() => {
    setCanSubmit(true)
  }, [setCanSubmit])

  return (
    <Form onSubmit={handleSubmit}>
      <NewMemberFormWrap>
        <Field
          name="email"
          component={InputField}
          normalize={normalizeEmail}
          validate={email}
          required
          placeholder="Введите E-mail"
        />
        <Field
          name="role"
          required
          component={DropdownReduxForm}
          selection
          onChange={handleChangeRole}
          placeholder="Выберите роль"
          options={roleDropdownOptions}
        />
        <Button
          basic
          positive
          disabled={!canSubmit}
          icon="add user"
          content="Пригласить"
          loading={isSubmitting}
        />
      </NewMemberFormWrap>
      <ErrorMessage error={error} />
    </Form>
  )
}

NewMemberForm.defaultProps = {
  error: null,
}

NewMemberForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
}

export default reduxForm({
  form: 'newMember',
})(NewMemberForm)
