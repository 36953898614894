import React from 'react'
import { render } from 'react-dom'
import Root from './containers/Root'
import configureStore from './stores/configureStore'
import { init } from './analytics'

const store = configureStore()

init()
render(<Root store={store} />, document.getElementById('root'))
