import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { AUTH_PAGE as redirectTo } from '../constants/routes'
import { fetchProfile, isFetching, getProfile, logout } from '../stores/user'

const mapStateToProps = (state) => ({
  username: getProfile(state).email,
  isFetching: isFetching(state),
})

class LoginButton extends Component {
  componentWillMount() {
    this.props.fetchProfile()
  }

  render() {
    const { username, isFetching, logout } = this.props
    if (username) {
      return (
        <Button
          as={Link}
          to={redirectTo}
          onClick={logout}
          size="tiny"
          loading={isFetching}
          icon
          labelPosition="right"
        >
          {username || 'Выйти'}
          <Icon name="sign out" />
        </Button>
      )
    }

    return (
      <Button as={Link} to={redirectTo} size="tiny" icon labelPosition="right">
        Войти
        <Icon name="sign in" />
      </Button>
    )
  }
}

LoginButton.defaultProps = {
  username: null,
}

LoginButton.propTypes = {
  username: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  fetchProfile: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { fetchProfile, logout })(LoginButton)
