import React from 'react'
import PropTypes from 'prop-types'
import { Button, Header, Segment } from 'semantic-ui-react'
import * as roles from '../../constants/user-roles'

const ArchiveProjectSegment = ({ project, onMoveToArchive }) => {
  const { id: projectId } = project
  // Use local flag to avoid show spinner in all segments on page
  const [isSubmitting, setSubmitting] = React.useState(false)
  const handleClick = React.useCallback(() => {
    setSubmitting(true)
    onMoveToArchive(projectId)
  }, [projectId, onMoveToArchive])

  if (project.userRole !== roles.OWNER || project.archived) {
    return null
  }

  // Reset local state
  if (isSubmitting && !project.isUpdating) {
    setSubmitting(false)
  }

  return (
    <Segment color="red">
      <Header>Архивация проекта</Header>
      <p>
        Перемещение проекта в архив приведет к тому, что
        <ul>
          <li>данные во всех сервисах будут недоступны для просмотра и редактирования.</li>
          <li>все запланированные задачи будут отклонены.</li>
          <li>
            все внешние сервисы, в разделе <em>Интеграции</em>, будут отключены.
          </li>
        </ul>
      </p>
      <p>
        Проект можно будет восстановить из архива, и данные станут доступны. Внешние сервисы нужно
        будет активировать вручную, в разделе <em>Интеграции</em>.
      </p>
      <Button
        negative
        loading={isSubmitting && (project.isUpdating || false)}
        onClick={handleClick}
      >
        Переместить в архив
      </Button>
    </Segment>
  )
}

ArchiveProjectSegment.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string,
    archived: PropTypes.bool,
    isUpdating: PropTypes.bool,
    userRole: PropTypes.string,
  }).isRequired,
  onMoveToArchive: PropTypes.func.isRequired,
}

export default ArchiveProjectSegment
