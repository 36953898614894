import {
  FETCH_LIST_FAILURE as FETCH_PROJECTS_LIST_FAILURE,
  FETCH_DETAILS_FAILURE as FETCH_PROJECT_DETAILS_FAILURE,
  CREATE_FAILURE as CREATE_PROJECT_FAILURE,
  UPDATE_FAILURE as UPDATE_PROJECT_FAILURE,
} from '../../projects'

const getNotificationHeader = (actionType) => {
  switch (actionType) {
    case FETCH_PROJECTS_LIST_FAILURE:
      return 'Загрузка проектов'
    case FETCH_PROJECT_DETAILS_FAILURE:
      return 'Загрузка данных по проекту'
    case CREATE_PROJECT_FAILURE:
      return 'Создание проекта'
    case UPDATE_PROJECT_FAILURE:
      return 'Изменение настроек проекта'
    // no default
  }

  return 'Неизвестное действие'
}

export default getNotificationHeader
