import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import notifications from './notifications'
import members from './members'
import projects from './projects'
import user from './user'

const rootReducer = combineReducers({
  notifications,
  members,
  projects,
  user,
  form: formReducer,
})

export default rootReducer
