import React, { Component } from 'react'
import { generatePath } from 'react-router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import memoize from 'memoize-one'
import * as routes from '../constants/routes'
import { fetchProjectDetails, getProject } from '../stores/projects'
import Breadcrumbs from '../components/Breadcrumbs'

const mapStateToProps = (
  state,
  {
    match: {
      params: { projectId },
    },
  }
) => {
  const project = getProject(state, projectId)
  return {
    projectName: project && project.name,
  }
}

const buildIndexItem = (params) => ({
  link: generatePath(routes.INDEX_PAGE, params),
  content: 'Проекты',
})
const buildProjectItem = (params, projectName) => ({
  link: generatePath(routes.PROJECT_INDEX_PAGE, params),
  content: projectName || 'Проект',
})

class BreadcrumbsContainer extends Component {
  breadcrumbItems = memoize((path, params, projectName) => {
    switch (path) {
      case routes.PROJECT_INDEX_PAGE:
      case routes.PROJECT_PAGE:
        return [buildIndexItem(params), buildProjectItem(params, projectName)]
      case routes.NEW_PROJECT_PAGE:
        return [
          buildIndexItem(params),
          {
            link: generatePath(routes.NEW_PROJECT_PAGE, params),
            content: 'Новый проект',
          },
        ]
      default:
        return [buildIndexItem(params)]
    }
  })

  componentDidMount() {
    const {
      projectName,
      fetchProjectDetails,
      match: {
        params: { projectId },
      },
    } = this.props

    if (!projectName && projectId) {
      fetchProjectDetails(projectId)
    }
  }

  componentDidUpdate() {
    const items = this.getBreadcrumbItems()
      .map((item) => item.content)
      .filter((item) => !!item)
      .reverse()

    items.push(this.props.baseTitle)

    document.title = items.join(' | ')
  }

  getBreadcrumbItems() {
    const { match, projectName } = this.props
    return this.breadcrumbItems(match.path, match.params, projectName)
  }

  render() {
    const items = this.getBreadcrumbItems()
    return <Breadcrumbs items={items} />
  }
}

BreadcrumbsContainer.defaultProps = {
  baseTitle: 'AirMedia.Projects',
  projectName: null,
}

BreadcrumbsContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    params: PropTypes.shape({
      projectId: PropTypes.string,
    }),
  }).isRequired,
  fetchProjectDetails: PropTypes.func.isRequired,
  projectName: PropTypes.string,
  baseTitle: PropTypes.string,
}

export default connect(mapStateToProps, { fetchProjectDetails })(BreadcrumbsContainer)
