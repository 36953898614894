import { ADMIN, ASSISTANT, CHIEF, OWNER } from './user-roles'

export const MEMBERS = 'members'
export const SERVICES = 'services'
export const INTEGRATIONS = 'integrations'
export const SETTINGS = 'settings'

export const getMenuItems = () => [
  {
    section: SERVICES,
    title: 'Сервисы',
    permissions: [OWNER, ADMIN, CHIEF, ASSISTANT],
  },
  {
    section: MEMBERS,
    title: 'Пользователи',
    permissions: [OWNER],
  },
  {
    section: INTEGRATIONS,
    title: 'Интеграции',
    permissions: [OWNER, ADMIN, CHIEF],
  },
  {
    section: SETTINGS,
    title: 'Настройки',
    permissions: [OWNER, ADMIN],
  },
]
