import React from 'react'
import PropTypes from 'prop-types'
import { Icon, List } from 'semantic-ui-react'
import ConnectionError from './integration/ConnectionError'
import ConnectionStatus from './integration/ConnectionStatus'
import IntegrationButton from './integration/IntegrationButton'
import ChooseWebProperty from './integration/ChooseWebProperty'
import RevokedTokenIcon from './integration/RevokedTokenIcon'
import ChangeDelegationAccountButton from './integration/ChangeDelegationAccountButton'

const IntegrationGoogleAnalytics = ({
  projectId,
  status,
  isConnecting,
  isUpdating,
  authLogin,
  webPropertyId,
  connectionError,
  onConnect,
  onDisable,
  setWebProperty,
}) => (
  <List.Item className="integration-item">
    <List.Header>
      <span>
        Google Analytics <ConnectionStatus status={status} />
      </span>
      <IntegrationButton
        projectId={projectId}
        status={status}
        isProcessing={isConnecting}
        onConnect={onConnect}
        onDisable={onDisable}
      />
    </List.Header>
    {authLogin && (
      <>
        <List.Content>
          <strong>Делегированный аккаунт:</strong> {authLogin}
          <RevokedTokenIcon status={status} />
          <ChangeDelegationAccountButton status={status} onClick={onConnect} />
          <List.Description>
            Все запросы к API будут выполнятся от имени этого пользователя.
            <br />
            Отозвать доступ можно в{' '}
            <a
              href="https://myaccount.google.com/permissions"
              target="_blank"
              rel="noopener noreferrer"
            >
              настройках
              {' '}
              <Icon name="external" size="small" />
            </a>{' '}
            Google-аккаунта.
          </List.Description>
        </List.Content>
        <List.Content>
          {webPropertyId ? (
            <>
              <strong>Ресурс:</strong> {webPropertyId}
            </>
          ) : (
            <ChooseWebProperty
              projectId={projectId}
              select={setWebProperty}
              isUpdating={isUpdating}
            />
          )}
          <List.Description>
            На уровне проекта будут доступны данные только в рамках выбранного ресурса. Это значение
            изменить нельзя.
          </List.Description>
        </List.Content>
      </>
    )}
    {connectionError && <ConnectionError error={connectionError} />}
  </List.Item>
)

IntegrationGoogleAnalytics.defaultProps = {
  authLogin: null,
  webPropertyId: null,
  isConnecting: false,
  isUpdating: false,
  connectionError: null,
}

IntegrationGoogleAnalytics.propTypes = {
  projectId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isConnecting: PropTypes.bool,
  isUpdating: PropTypes.bool,
  authLogin: PropTypes.string,
  webPropertyId: PropTypes.string,
  connectionError: PropTypes.string,
  onConnect: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
  setWebProperty: PropTypes.func.isRequired,
}

export default IntegrationGoogleAnalytics
