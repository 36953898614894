import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Container, Grid } from 'semantic-ui-react'
import NewProjectForm from '../../containers/forms/NewProjectForm'

const NewProjectPageWrap = styled.div`
  padding-top: 50px;
`

const NewProjectPage = ({ isCreating, createProject }) => (
  <NewProjectPageWrap>
    <Container>
      <Grid columns={1} centered>
        <Grid.Row>
          <Grid.Column width={6}>
            <NewProjectForm isCreating={isCreating} onSubmit={createProject} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </NewProjectPageWrap>
)

NewProjectPage.propTypes = {
  createProject: PropTypes.func.isRequired,
  isCreating: PropTypes.bool.isRequired,
}

export default NewProjectPage
