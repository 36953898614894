import React from 'react'
import PropTypes from 'prop-types'
import { Dimmer, Grid, Header, Loader, Table } from 'semantic-ui-react'
import ErrorHandlerLocal from '../containers/ErrorHandlerLocal'
import NewMemberForm from '../containers/forms/NewMemberForm'
import ErrorMessage from '../containers/forms/ErrorMessage'
import MemberItem from './MemberItem'

const Members = ({ onAddMember, onRemoveMember, members, isFetching, isSubmitting, error }) => {
  if (isFetching) {
    return (
      <Dimmer active inverted>
        <Loader
          size="large"
          content="Загрузка списка пользователей.."
          active
          className="screen-loader"
        />
      </Dimmer>
    )
  }

  return (
    <ErrorHandlerLocal>
      <Grid centered>
        <Grid.Row>
          <Grid.Column width={12}>
            <Header as="h2" content="Пользователи" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={12}>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={7}>E-mail</Table.HeaderCell>
                  <Table.HeaderCell width={5}>Роль</Table.HeaderCell>
                  <Table.HeaderCell width={4} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {members.map((member) => (
                  <MemberItem key={member.id} {...member} onRemove={onRemoveMember} />
                ))}
              </Table.Body>
            </Table>
            <ErrorMessage error={error} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={12}>
            <NewMemberForm onSubmit={onAddMember} isSubmitting={isSubmitting} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ErrorHandlerLocal>
  )
}

Members.defaultProps = {
  error: null,
}

Members.propTypes = {
  onAddMember: PropTypes.func.isRequired,
  onRemoveMember: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

export default Members
