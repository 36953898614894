import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, Grid, Message } from 'semantic-ui-react'
import isGranted from '../../hoc/isGranted'
import { ADMIN, OWNER } from '../../constants/user-roles'
import useFetch from '../../hooks/useFetch'
import * as api from '../../api/googleAnalytics'

const itemsToOptions = (items) =>
  items.map((item) => ({
    key: item.id,
    text: item.name,
    value: item.id,
  }))

const ChooseWebProperty = ({ projectId, select, isUpdating }) => {
  const [selectedAccount, setAccount] = useState(null)
  const [selectedWebProperty, setWebProperty] = useState(null)

  const fetchAccounts = useCallback(() => api.fetchAccounts(projectId), [projectId])
  const fetchWebProperties = useCallback(() => {
    if (selectedAccount) {
      return api.fetchWebProperties(projectId, selectedAccount)
    }
    return []
  }, [projectId, selectedAccount])

  const [accounts, accountsIsFetching] = useFetch(fetchAccounts, [], [projectId])
  const [webProperties, webPropertiesIsFetching] = useFetch(
    fetchWebProperties,
    [],
    [projectId, selectedAccount]
  )

  const onChangeAccount = (e, { value }) => {
    setWebProperty(null)
    setAccount(value)
  }
  const onChangeWebProperty = (e, { value }) => {
    setWebProperty(value)
  }

  const onSave = selectedWebProperty
    ? () => {
        select(selectedAccount, selectedWebProperty)
      }
    : null

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={7}>
          <Dropdown
            placeholder="Выберите аккаунт..."
            fluid
            search
            selection
            loading={accountsIsFetching}
            options={itemsToOptions(accounts)}
            onChange={onChangeAccount}
          />
        </Grid.Column>
        <Grid.Column width={7}>
          <Dropdown
            placeholder="Выберите ресурс..."
            fluid
            search
            selection
            disabled={!selectedAccount}
            loading={webPropertiesIsFetching}
            options={itemsToOptions(webProperties)}
            onChange={onChangeWebProperty}
          />
        </Grid.Column>
        <Grid.Column width={2}>
          <Button
            primary
            content="Сохранить"
            disabled={!selectedWebProperty}
            loading={isUpdating}
            onClick={onSave}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

ChooseWebProperty.defaultProps = {
  isUpdating: false,
}

ChooseWebProperty.propTypes = {
  projectId: PropTypes.string.isRequired,
  select: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
}

const NoRights = () => <Message>Процесс интеграции не завершён: веб-ресурс ещё не выбран.</Message>

export default isGranted([OWNER, ADMIN])(ChooseWebProperty, NoRights)
