import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components/macro'
import Notification from '../components/Notification'
import { removeNotification } from '../stores/notifications'

const NotificationEmitterWrap = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;
  max-width: 500px;
`

const mapStateToProps = (state) => ({
  notifications: state.notifications.slice(0, 10),
})

const NotificationEmitter = ({ notifications, removeNotification }) => (
  <NotificationEmitterWrap>
    {notifications.map((item) => (
      <Notification key={item.id} {...item} removeNotification={removeNotification} />
    ))}
  </NotificationEmitterWrap>
)

NotificationEmitter.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeNotification: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { removeNotification })(NotificationEmitter)
