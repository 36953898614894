import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { ApiError } from '@airmedia/api-utils'
import getMessage from '../utils/getMessage'
import Members from '../components/Members'
import {
  addMember,
  fetchMembers,
  getMembers,
  isMemberAdding,
  isMembersFetching,
  removeMember,
} from '../stores/members'

const getErrorByStatus = (status) => {
  const prefix = 'new_member.form.error.'
  switch (status) {
    case 403:
    case 404:
    case 409:
      return getMessage(`${prefix}${status}`)
    // no default
  }
  return getMessage(`${prefix}generic`)
}

const mapStateToProps = (state) => ({
  members: getMembers(state),
  isFetching: isMembersFetching(state),
  isAdding: isMemberAdding(state),
})

class MembersContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
    }
  }

  componentDidMount() {
    this.props.fetchMembers(this.props.projectId)
  }

  handleAddMember = ({ email, role }) => {
    const { addMember, projectId } = this.props
    return new Promise((resolve, reject) => {
      addMember(projectId, email, role, resolve, (err) => {
        if (err instanceof ApiError) {
          if (err.status === 422 && err.payload.errors) {
            const serverErrors = err.payload.errors.map(({ property, code }) => {
              return getMessage(`new_member.form.${property.substring(5)}.error.${code}`)
            })
            const error = {
              _error: serverErrors.length
                ? serverErrors
                : getMessage('new_member.form.error.generic'),
            }

            reject(new SubmissionError(error))
          } else {
            reject(
              new SubmissionError({
                _error: getErrorByStatus(err.status),
              })
            )
          }
        } else {
          reject(err)
        }
      })
    })
  }

  handleRemoveMember = (memberId) => {
    this.props.removeMember(this.props.projectId, memberId)
  }

  render() {
    const { projectId, members, isFetching, isAdding } = this.props

    return (
      <Members
        error={this.state.error}
        projectId={projectId}
        onAddMember={this.handleAddMember}
        onRemoveMember={this.handleRemoveMember}
        members={members}
        isFetching={isFetching}
        isSubmitting={isAdding}
      />
    )
  }
}

MembersContainer.propTypes = {
  fetchMembers: PropTypes.func.isRequired,
  addMember: PropTypes.func.isRequired,
  removeMember: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      isRemoving: PropTypes.bool,
    })
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isAdding: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, { fetchMembers, addMember, removeMember })(MembersContainer)
