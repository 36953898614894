import React from 'react'
import PropTypes from 'prop-types'
import { Message } from 'semantic-ui-react'
import * as integration from '../../constants/integrations'

const getDefaultMessage = (error) => {
  switch (error) {
    case integration.ERROR_ACCESS_DENIED:
      return 'Подтвердите доступ для приложения.'
    case integration.ERROR_BAD_REQUEST:
    case integration.ERROR_FORBIDDEN:
      return 'На данный момент подключение недоступно. Если проблема сохранится, обратитесь в поддержку.'
    case integration.ERROR_CONFLICT:
      return 'Ошибка при подключении. Доступ приложению успешно предоставлен, но у внешнего аккаунта нет доступа к закрепленному ресурсу. Попробуйте авторизоваться под другим аккаунтом при предоставлении доступа приложению на странице соглашения.'
    default:
      return 'Произошла неизвестная ошибка.'
  }
}

const ConnectionError = ({ error, message }) => (
  <Message negative>{message || getDefaultMessage(error)}</Message>
)

ConnectionError.defaultProps = {
  message: null,
}

ConnectionError.propTypes = {
  error: PropTypes.string.isRequired,
  message: PropTypes.string,
}

export default ConnectionError
