import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { Button, Card, List } from 'semantic-ui-react'
import { LongDate } from '@airmedia/react-time'
import { TEXT_COLOR_MUTED } from '../styles/variables'

const StyledListItem = styled(List.Item)`
  display: flex !important;
  align-items: center;

  .ui.card {
    margin-bottom: 0;

    .content .meta {
      margin-top: 0.5em;
    }

    .content .description {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &.archived > .ui.card > .content > .header {
    color: ${TEXT_COLOR_MUTED};
  }
`

const ProjectsListItem = ({ item, unarchive }) => {
  const handleUnarchive = useCallback(() => {
    unarchive(item.id)
  }, [unarchive, item.id])

  return (
    <StyledListItem className={item.archived ? 'archived' : null} active={item.isUpdating || false}>
      <Card fluid as={item.archived ? null : Link} to={item.archived ? null : `/${item.id}`}>
        <Card.Content>
          <Card.Header>{item.name}</Card.Header>
          <Card.Meta textAlign="right">
            <LongDate date={item.createdAt} />
          </Card.Meta>
          {item.archived ? (
            <Card.Description>
              В архиве
              <Button
                basic
                icon="undo"
                color="blue"
                size="mini"
                loading={item.isUpdating || false}
                onClick={handleUnarchive}
              />
            </Card.Description>
          ) : null}
        </Card.Content>
      </Card>
    </StyledListItem>
  )
}

ProjectsListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    archived: PropTypes.bool.isRequired,
    createdAt: PropTypes.string.isRequired,
    isUpdating: PropTypes.bool,
  }).isRequired,
  unarchive: PropTypes.func.isRequired,
}

export default ProjectsListItem
