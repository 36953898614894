import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import { omit } from 'lodash'
import * as types from './constants'
import * as utils from '../utils'

export * from './actions'
export * from './constants'
export * from './selectors'

const getMemberId = (action) => action.memberId
const makeUpdateMember = (data) => utils.makeUpdateEntity(getMemberId, data)
const mergeMembers = utils.makeMergeEntities('members')

export default combineReducers({
  byId: handleActions(
    {
      [types.FETCH_LIST_SUCCESS]: (state, action) => action.entities.members,
      [types.ADD_SUCCESS]: mergeMembers,
      [types.REMOVE_REQUEST]: makeUpdateMember({ isRemoving: true }),
      [types.REMOVE_SUCCESS]: (state, action) => omit(state, [action.memberId]),
      [types.REMOVE_FAILURE]: makeUpdateMember({ isRemoving: false }),
    },
    {}
  ),
  ids: handleActions(
    {
      [types.FETCH_LIST_SUCCESS]: (state, action) => action.result || [],
      [types.ADD_SUCCESS]: utils.mergeIds,
      [types.REMOVE_SUCCESS]: (state, action) => state.filter((id) => id !== action.memberId),
    },
    []
  ),
  isFetching: handleActions(
    {
      [types.FETCH_LIST_REQUEST]: utils.setTrue,
      [types.FETCH_LIST_SUCCESS]: utils.setFalse,
      [types.FETCH_LIST_FAILURE]: utils.setFalse,
    },
    false
  ),
  isAdding: handleActions(
    {
      [types.ADD_REQUEST]: utils.setTrue,
      [types.ADD_SUCCESS]: utils.setFalse,
      [types.ADD_FAILURE]: utils.setFalse,
    },
    false
  ),
})
