import React from 'react'
import PropTypes from 'prop-types'
import { Icon, List } from 'semantic-ui-react'
import ConnectionError from './integration/ConnectionError'
import ConnectionStatus from './integration/ConnectionStatus'
import IntegrationButton from './integration/IntegrationButton'
import ChooseYandexDirectClient from './integration/ChooseYandexDirectClient'
import RevokedTokenIcon from './integration/RevokedTokenIcon'
import ChangeDelegationAccountButton from './integration/ChangeDelegationAccountButton'

const IntegrationYandexDirect = ({
  projectId,
  status,
  isConnecting,
  isUpdating,
  authLogin,
  clientLogin,
  connectionError,
  onConnect,
  onDisable,
  setClient,
}) => (
  <List.Item className="integration-item">
    <List.Header>
      <span>
        Яндекс.Директ <ConnectionStatus status={status} />
      </span>
      <IntegrationButton
        projectId={projectId}
        status={status}
        isProcessing={isConnecting}
        onConnect={onConnect}
        onDisable={onDisable}
      />
    </List.Header>
    {authLogin && (
      <>
        <List.Content>
          <strong>Делегированный аккаунт:</strong> {authLogin}
          <RevokedTokenIcon status={status} />
          <ChangeDelegationAccountButton status={status} onClick={onConnect} />
          <List.Description>
            Все запросы к API будут выполнятся от имени этого пользователя.
            <br />
            Отозвать доступ можно в{' '}
            <a
              href="https://passport.yandex.ru/profile/access"
              target="_blank"
              rel="noopener noreferrer"
            >
              настройках{' '}
              <Icon name="external" size="small" />
            </a>{' '}
            Яндекс-аккаунта.
          </List.Description>
        </List.Content>
        <List.Content>
          {clientLogin ? (
            <>
              <strong>Рекламодатель:</strong> {clientLogin}
            </>
          ) : (
            <ChooseYandexDirectClient
              projectId={projectId}
              select={setClient}
              isUpdating={isUpdating}
            />
          )}
          <List.Description>
            На уровне проекта будут доступны данные только в рамках выбранного рекламодателя. Это
            значение изменить нельзя.
          </List.Description>
        </List.Content>
      </>
    )}
    {connectionError && <ConnectionError error={connectionError} />}
  </List.Item>
)

IntegrationYandexDirect.defaultProps = {
  authLogin: null,
  clientLogin: null,
  isConnecting: false,
  isUpdating: false,
  connectionError: null,
}

IntegrationYandexDirect.propTypes = {
  projectId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isConnecting: PropTypes.bool,
  isUpdating: PropTypes.bool,
  authLogin: PropTypes.string,
  clientLogin: PropTypes.string,
  connectionError: PropTypes.string,
  onConnect: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
  setClient: PropTypes.func.isRequired,
}

export default IntegrationYandexDirect
