import React from 'react'
import PropTypes from 'prop-types'
import { Icon, List } from 'semantic-ui-react'
import ConnectionError from './integration/ConnectionError'
import ConnectionStatus from './integration/ConnectionStatus'
import IntegrationButton from './integration/IntegrationButton'
import ChooseYandexMetrikaCounter from './integration/ChooseYandexMetrikaCounter'
import RevokedTokenIcon from './integration/RevokedTokenIcon'
import ChangeDelegationAccountButton from './integration/ChangeDelegationAccountButton'
import { STATUS_UNCONNECTED } from '../constants/integrations'

const IntegrationYandexMetrika = ({
  projectId,
  integrations,
  onConnect,
  onDisable,
  onSelectCounter,
  isConnecting,
  connectionError,
}) => (
  <>
    {integrations.map((item) => (
      <List.Item key={item.counterId} className="integration-item">
        <List.Header>
          <span>
            Яндекс.Метрика <ConnectionStatus status={item.status} />
          </span>
          <IntegrationButton
            projectId={projectId}
            status={item.status}
            isProcessing={item.isUpdating || false}
            onConnect={() => onConnect(item.counterId)}
            onDisable={() => onDisable(item.counterId)}
          />
        </List.Header>
        <>
          <List.Content>
            <strong>Делегированный аккаунт:</strong> {item.tokenOwnerLogin}
            <RevokedTokenIcon status={item.status} />
            <ChangeDelegationAccountButton
              status={item.status}
              onClick={() => onConnect(item.counterId, true)}
            />
            <List.Description>
              Все запросы к API будут выполнятся от имени этого пользователя.
              <br />
              Отозвать доступ можно в{' '}
              <a
                href="https://passport.yandex.ru/profile/access"
                target="_blank"
                rel="noopener noreferrer"
              >
                настройках{' '}
                <Icon name="external" size="small" />
              </a>{' '}
              Яндекс-аккаунта.
            </List.Description>
          </List.Content>
          <List.Content>
            {item.counterId ? (
              <>
                <strong>Счетчик:</strong> {item.counterId}
              </>
            ) : (
              <ChooseYandexMetrikaCounter
                projectId={projectId}
                tokenOwnerId={item.tokenOwnerId}
                select={onSelectCounter}
                isUpdating={item.isUpdating}
              />
            )}
            <List.Description>
              На уровне проекта будут доступны данные только в рамках выбранного счетчика. Это
              значение изменить нельзя.
            </List.Description>
          </List.Content>
        </>
        {item.connectionError && <ConnectionError error={item.connectionError} />}
      </List.Item>
    ))}
    <List.Item className="integration-item">
      <List.Header>
        <span>
          Яндекс.Метрика <ConnectionStatus status={STATUS_UNCONNECTED} />
        </span>
        <IntegrationButton
          projectId={projectId}
          status={STATUS_UNCONNECTED}
          isProcessing={isConnecting}
          onConnect={onConnect}
          onDisable={onDisable}
        />
      </List.Header>
      {connectionError && <ConnectionError error={connectionError} />}
    </List.Item>
  </>
)

IntegrationYandexMetrika.defaultProps = {
  isConnecting: false,
  connectionError: null,
}

IntegrationYandexMetrika.propTypes = {
  projectId: PropTypes.string.isRequired,
  integrations: PropTypes.arrayOf(
    PropTypes.shape({
      tokenOwnerId: PropTypes.string.isRequired,
      tokenOwnerLogin: PropTypes.string.isRequired,
      counterId: PropTypes.number,
      status: PropTypes.string.isRequired,
      isUpdating: PropTypes.bool,
    })
  ).isRequired,
  isConnecting: PropTypes.bool,
  onConnect: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
  onSelectCounter: PropTypes.func.isRequired,
  connectionError: PropTypes.string,
}

export default IntegrationYandexMetrika
