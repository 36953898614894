const BASE_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : window.location.origin

export function absoluteUrl(url) {
  if (url.indexOf('/') === 0) {
    return `${BASE_URL}${url}`
  }

  return `${BASE_URL}/${url}`
}
