export default (url, name, width, height) => {
  const actualWidth = Math.min(width, window.outerWidth)
  const actualHeight = Math.min(height, window.outerHeight)
  const offsetX = (window.outerWidth - actualWidth) / 2
  const offsetY = (window.outerHeight - actualHeight) / 2

  return window.open(
    url,
    name,
    `width=${actualWidth},height=${actualHeight},screenX=${offsetX},screenY=${offsetY}`
  )
}
