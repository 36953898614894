import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Grid, List } from 'semantic-ui-react'
import { SIZE_HUGE, SIZE_SMALL, TEXT_COLOR_LIGHT } from '../styles/variables'
import ErrorHandlerLocal from '../containers/ErrorHandlerLocal'
import IntegrationYandexDirect from '../containers/IntegrationYandexDirect'
import IntegrationYandexMetrika from '../containers/IntegrationYandexMetrika'
import IntegrationGoogleAnalytics from '../containers/IntegrationGoogleAnalytics'

const IntegrationsList = styled(List)`
  &.list.ui > .integration-item {
    padding: 15px 0;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 20px;
      font-size: ${SIZE_HUGE}rem;
    }

    .content {
      margin-bottom: 15px;

      & > .description {
        padding-top: 0.2rem;
        font-size: ${SIZE_SMALL}rem;
        color: ${TEXT_COLOR_LIGHT};
      }
    }
  }
`

const Integrations = ({ projectId }) => (
  <ErrorHandlerLocal>
    <Grid centered>
      <Grid.Row>
        <Grid.Column width={12}>
          <IntegrationsList divided>
            <IntegrationYandexDirect projectId={projectId} />
            <IntegrationYandexMetrika projectId={projectId} />
            <IntegrationGoogleAnalytics projectId={projectId} />
          </IntegrationsList>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </ErrorHandlerLocal>
)

Integrations.propTypes = {
  projectId: PropTypes.string.isRequired,
}

export default Integrations
