import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Form } from 'semantic-ui-react'
import ErrorMessage from './ErrorMessage'

const InputFieldWrap = styled.div`
  margin-bottom: 1em;
`

const InputField = ({ meta: { touched, error }, input, ...rest }) => (
  <InputFieldWrap>
    <Form.Input {...input} {...rest} />
    {touched && error && <ErrorMessage error={error} />}
  </InputFieldWrap>
)

InputField.propTypes = {
  input: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
}

export default InputField
