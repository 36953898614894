import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import * as integration from '../../constants/integrations'

const StyledLink = styled('a')`
  display: inline-block;
  margin-left: 1rem;
`

const ChangeDelegationAccountButton = ({ status, onClick }) => {
  const handleClick = React.useCallback(() => {
    onClick(true)
  }, [onClick])

  if (status === integration.STATUS_UNCONNECTED) {
    return null
  }

  return <StyledLink onClick={handleClick}>сменить</StyledLink>
}

ChangeDelegationAccountButton.propTypes = {
  status: PropTypes.oneOf([
    integration.STATUS_CONNECTED,
    integration.STATUS_DISABLED,
    integration.STATUS_PENDING,
    integration.STATUS_REVOKED,
    integration.STATUS_UNCONNECTED,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ChangeDelegationAccountButton
