import { useState, useEffect } from 'react'

export default (fn, initialState = null) => {
  const [data, setData] = useState(initialState)
  const [fetching, setFetching] = useState(false)

  useEffect(() => {
    let didCancel = false

    async function doFetch() {
      try {
        setFetching(true)
        const data = await fn()

        if (!didCancel) {
          setData(data)
        }
      } finally {
        if (!didCancel) {
          setFetching(false)
        }
      }
    }

    doFetch()

    return () => {
      didCancel = true
    }
  }, [fn])

  return [data, fetching]
}
