import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { COLOR_GREEN, COLOR_GREY, COLOR_RED } from '../../styles/variables'
import * as integration from '../../constants/integrations'

const Icon = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  line-height: 8px;
  font-size: 8px;
  border-radius: 50%;
  background-color: ${COLOR_GREY};
  box-shadow: 0 0 3px 0 ${COLOR_GREY};
  vertical-align: middle;
  margin-top: -2px;

  &.active {
    background-color: ${COLOR_GREEN};
    box-shadow: 0 0 3px 0 ${COLOR_GREEN};
  }

  &.disabled {
    background-color: ${COLOR_RED};
    box-shadow: 0 0 3px 0 ${COLOR_RED};
  }
`

const ConnectionStatus = ({ status }) => {
  switch (status) {
    case integration.STATUS_CONNECTED:
      return <Icon className="active" />
    case integration.STATUS_DISABLED:
    case integration.STATUS_REVOKED:
      return <Icon className="disabled" />
    default:
      return <Icon />
  }
}

ConnectionStatus.propTypes = {
  status: PropTypes.oneOf([
    integration.STATUS_CONNECTED,
    integration.STATUS_DISABLED,
    integration.STATUS_PENDING,
    integration.STATUS_REVOKED,
    integration.STATUS_UNCONNECTED,
  ]).isRequired,
}

export default ConnectionStatus
