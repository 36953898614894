import { camelizeKeys } from 'humps'
import callApi from './callApi'

const getData = (payload) => payload.data.map((item) => camelizeKeys(item))

export async function fetchAccounts(projectId) {
  const payload = await callApi('GET', `/api/projects/${projectId}/google-analytics/accounts`)

  return getData(payload)
}

export async function fetchWebProperties(projectId, accountId) {
  const endpoint = `/api/projects/${projectId}/google-analytics/accounts/${accountId}/web-properties`
  const payload = await callApi('GET', endpoint)

  return getData(payload)
}

export async function disable(projectId) {
  return callApi('PUT', `/api/projects/${projectId}/google-analytics/disable`)
}

export async function enable(projectId) {
  return callApi('DELETE', `/api/projects/${projectId}/google-analytics/disable`)
}
