import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as routes from '../constants/routes'
import ProjectPage from '../components/pages/ProjectPage'
import { fetchProjectDetails, getProject, archiveProject } from '../stores/projects'

const mapStateToProps = (
  state,
  {
    match: {
      params: { projectId, section },
    },
  }
) => ({
  project: getProject(state, projectId),
  section,
  projectId,
})

class ProjectPageContainer extends Component {
  componentDidMount() {
    this.props.fetchProjectDetails(this.props.projectId)
  }

  selectMenuItem = (newSection) => {
    const { history, section } = this.props
    const newLocation = history.location.pathname.split('/')

    if (newSection !== section) {
      newLocation.splice(2, 1, newSection)
      history.push(newLocation.join('/'))
    }
  }

  handleMoveToArchive = (projectId) => {
    const { archiveProject, history } = this.props
    archiveProject(projectId, () => {
      history.push(routes.INDEX_PAGE)
    })
  }

  render() {
    const { project, section } = this.props

    if (!project) {
      return null
    }

    return (
      <ProjectPage
        project={project}
        section={section}
        selectMenuItem={this.selectMenuItem}
        onMoveToArchive={this.handleMoveToArchive}
      />
    )
  }
}

ProjectPageContainer.defaultProps = {
  project: null,
}

ProjectPageContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    createdAt: PropTypes.string,
    isFetching: PropTypes.bool,
    yandexDirectStatus: PropTypes.string,
  }),
  fetchProjectDetails: PropTypes.func.isRequired,
  section: PropTypes.string.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  archiveProject: PropTypes.func.isRequired,
}

export default withRouter(
  connect(mapStateToProps, { fetchProjectDetails, archiveProject })(ProjectPageContainer)
)
