import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Button, Confirm } from 'semantic-ui-react'
import * as integration from '../../constants/integrations'
import isGranted from '../../hoc/isGranted'
import { ADMIN, OWNER } from '../../constants/user-roles'

const IntegrationButton = ({ status, isProcessing, onConnect, onDisable }) => {
  const [confirmOpen, setConfirmOpen] = useState(false)

  const handleDisable = useCallback(() => {
    setConfirmOpen(true)
  }, [])
  const handleConfirm = useCallback(() => {
    onDisable()
    setConfirmOpen(false)
  }, [onDisable])
  const handleCancel = useCallback(() => {
    setConfirmOpen(false)
  }, [])

  switch (status) {
    case integration.STATUS_DISABLED:
    case integration.STATUS_REVOKED:
    case integration.STATUS_PENDING:
    case integration.STATUS_UNCONNECTED:
      return (
        <Button
          basic
          loading={isProcessing}
          positive
          content={status === integration.STATUS_PENDING ? 'Переподключить' : 'Подключить'}
          onClick={() => onConnect()}
        />
      )
    case integration.STATUS_CONNECTED:
      return (
        <>
          <Button
            basic
            loading={isProcessing}
            negative
            content="Отключить"
            onClick={handleDisable}
          />
          <Confirm
            open={confirmOpen}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            cancelButton="Отмена"
            header="Вы точно хотите отключить этот сервис?"
            content={
              <div className="content">
                <p>После отключения сервиса некоторый функционал будет недоступен.</p>
                <p>Позже, сервис можно будет подключить снова.</p>
              </div>
            }
          />
        </>
      )
    // no default
  }

  return null
}

IntegrationButton.defaultProps = {
  isProcessing: false,
}

IntegrationButton.propTypes = {
  status: PropTypes.oneOf([
    integration.STATUS_CONNECTED,
    integration.STATUS_DISABLED,
    integration.STATUS_PENDING,
    integration.STATUS_REVOKED,
    integration.STATUS_UNCONNECTED,
  ]).isRequired,
  isProcessing: PropTypes.bool,
  onConnect: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
}

export default isGranted([OWNER, ADMIN])(IntegrationButton)
