import * as types from './constants'

export const setArchivedState = (archivedState) => ({
  archivedState,
  type: types.SET_ARCHIVED_STATE,
})

export const createProject = (project, callback = null) => ({
  callback,
  project,
  type: types.CREATE_REQUEST,
})

createProject.success = (projectId, payload) => ({
  ...payload,
  projectId,
  type: types.CREATE_SUCCESS,
})

createProject.failure = (error) => ({
  error,
  type: types.CREATE_FAILURE,
})

export const fetchProjects = () => ({
  type: types.FETCH_LIST,
})

fetchProjects.request = () => ({
  type: types.FETCH_LIST_REQUEST,
})
fetchProjects.success = (payload, nextPageOffset) => ({
  ...payload,
  nextPageOffset,
  type: types.FETCH_LIST_SUCCESS,
})
fetchProjects.failure = (error, requestId = null) => ({
  error,
  requestId,
  type: types.FETCH_LIST_FAILURE,
})

export const fetchProjectDetails = (projectId, force = false) => ({
  projectId,
  force,
  type: types.FETCH_DETAILS,
})

fetchProjectDetails.request = (projectId) => ({
  projectId,
  type: types.FETCH_DETAILS_REQUEST,
})

fetchProjectDetails.success = (projectId, payload) => ({
  ...payload,
  projectId,
  type: types.FETCH_DETAILS_SUCCESS,
})

fetchProjectDetails.failure = (projectId, error, requestId = null) => ({
  error,
  projectId,
  requestId,
  type: types.FETCH_DETAILS_FAILURE,
})

export const updateProject = (projectId, project) => ({
  projectId,
  project,
  type: types.UPDATE_REQUEST,
})

updateProject.success = (projectId, payload) => ({
  ...payload,
  projectId,
  type: types.UPDATE_SUCCESS,
})

updateProject.failure = (projectId, error) => ({
  error,
  projectId,
  type: types.UPDATE_FAILURE,
})

export const archiveProject = (projectId, onSuccess = null) => ({
  projectId,
  meta: {
    onSuccess,
  },
  type: types.ARCHIVE_REQUEST,
})

archiveProject.success = (projectId, shouldDelete) => ({
  projectId,
  shouldDelete,
  type: types.ARCHIVE_SUCCESS,
})

archiveProject.failure = (projectId, error) => ({
  projectId,
  error,
  type: types.ARCHIVE_FAILURE,
})

export const unarchiveProject = (projectId) => ({
  projectId,
  type: types.UNARCHIVE_REQUEST,
})

unarchiveProject.success = (projectId, shouldDelete) => ({
  projectId,
  shouldDelete,
  type: types.UNARCHIVE_SUCCESS,
})

unarchiveProject.failure = (projectId, error) => ({
  projectId,
  error,
  type: types.UNARCHIVE_FAILURE,
})

export const disableYandexDirect = (projectId) => ({
  projectId,
  type: types.DISABLE_YANDEX_DIRECT_REQUEST,
})

disableYandexDirect.success = (projectId) => ({
  projectId,
  type: types.DISABLE_YANDEX_DIRECT_SUCCESS,
})

disableYandexDirect.failure = (projectId, error) => ({
  projectId,
  error,
  type: types.DISABLE_YANDEX_DIRECT_FAILURE,
})

export const disableYandexMetrika = (projectId, counterId) => ({
  projectId,
  counterId,
  type: types.DISABLE_YANDEX_METRIKA_REQUEST,
})

disableYandexMetrika.success = (projectId, counterId) => ({
  projectId,
  counterId,
  type: types.DISABLE_YANDEX_METRIKA_SUCCESS,
})

disableYandexMetrika.failure = (projectId, counterId, error) => ({
  projectId,
  counterId,
  error,
  type: types.DISABLE_YANDEX_METRIKA_FAILURE,
})

export const assignYandexMetrikaCounter = (projectId, tokenOwnerId, counterId) => ({
  projectId,
  tokenOwnerId,
  counterId,
  type: types.ASSIGN_YANDEX_METRIKA_COUNTER_REQUEST,
})

assignYandexMetrikaCounter.success = (projectId, tokenOwnerId, counterId) => ({
  projectId,
  tokenOwnerId,
  counterId,
  type: types.ASSIGN_YANDEX_METRIKA_COUNTER_SUCCESS,
})

assignYandexMetrikaCounter.failure = (projectId, tokenOwnerId, error) => ({
  error,
  projectId,
  tokenOwnerId,
  type: types.ASSIGN_YANDEX_METRIKA_COUNTER_FAILURE,
})

export const disableGoogleAnalytics = (projectId) => ({
  projectId,
  type: types.DISABLE_GOOGLE_ANALYTICS_REQUEST,
})

disableGoogleAnalytics.success = (projectId) => ({
  projectId,
  type: types.DISABLE_GOOGLE_ANALYTICS_SUCCESS,
})

disableGoogleAnalytics.failure = (projectId, error) => ({
  projectId,
  error,
  type: types.DISABLE_GOOGLE_ANALYTICS_FAILURE,
})

export const enableIntegration = (projectId, service, reconnect = false, params = {}) => ({
  projectId,
  service,
  reconnect,
  params,
  type: types.ENABLE_INTEGRATION,
})

enableIntegration.success = (projectId, service, params, connectionError) => ({
  projectId,
  service,
  params,
  connectionError,
  type: types.ENABLE_INTEGRATION_SUCCESS,
})

enableIntegration.failure = (projectId, service, params, error) => ({
  projectId,
  service,
  params,
  error,
  type: types.ENABLE_INTEGRATION_FAILURE,
})
