import { camelize, camelizeKeys, decamelizeKeys } from 'humps'
import { schema } from 'normalizr'
import { omit } from 'lodash'
import { buildUrl } from '@airmedia/api-utils'
import callApi from './callApi'

export const ARCHIVED_STATE_ALL = 'all'
export const ARCHIVED_STATE_ARCHIVED = 'archived'
export const ARCHIVED_STATE_NOT_ARCHIVED = 'not_archived'

const PAGE_SIZE_DEFAULT = 20

const mergeToParent = (entity, keyOfValueObject) => {
  if (entity[keyOfValueObject]) {
    return omit(
      Object.entries(entity[keyOfValueObject]).reduce((acc, [key, value]) => {
        acc[camelize(`${keyOfValueObject} ${key}`)] = value
        return acc
      }, entity),
      keyOfValueObject
    )
  }
  return entity
}

const projectSchema = new schema.Entity(
  'projects',
  {},
  {
    processStrategy: (entity) => {
      let result = camelizeKeys(entity)
      result = mergeToParent(result, 'googleAnalytics')
      result = mergeToParent(result, 'user')
      result = mergeToParent(result, 'yandexDirect')

      return result
    },
  }
)

export async function fetchProjects(archivedState, offset = 0, limit = PAGE_SIZE_DEFAULT) {
  const endpoint = buildUrl('/api/projects', {
    archived_state: archivedState,
    offset,
    limit,
  })
  return callApi('GET', endpoint, {
    schema: [projectSchema],
  })
}

export async function fetchProjectDetails(projectId) {
  return callApi('GET', `/api/projects/${projectId}`, {
    schema: projectSchema,
  })
}

export async function createProject(project) {
  return callApi('POST', '/api/projects', {
    data: decamelizeKeys(project),
    schema: projectSchema,
  })
}

export async function updateProject(projectId, projectDiff) {
  const { name, yandexDirectClientLogin, googleAnalyticsAccountId, googleAnalyticsWebPropertyId } =
    projectDiff
  const payload = {}

  if (name) {
    payload.name = name
  }
  if (yandexDirectClientLogin) {
    payload.yandex_direct = {
      client_login: yandexDirectClientLogin,
    }
  }
  if (googleAnalyticsWebPropertyId) {
    payload.google_analytics = {
      account_id: `${googleAnalyticsAccountId}`,
      web_property_id: googleAnalyticsWebPropertyId,
    }
  }

  if (!Object.keys(payload).length) {
    throw new Error('Payload cannot be empty')
  }

  return callApi('PATCH', `/api/projects/${projectId}`, {
    data: payload,
    schema: projectSchema,
  })
}

export async function archiveProject(projectId) {
  return callApi('PUT', `/api/projects/${projectId}/archive`)
}

export async function unarchiveProject(projectId) {
  return callApi('DELETE', `/api/projects/${projectId}/archive`)
}
