import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Container, Sidebar, Loader, Dimmer, Header } from 'semantic-ui-react'
import * as projectMenus from '../../constants/project-menus'
import Integrations from '../Integrations'
import Members from '../../containers/Members'
import Services from '../Services'
import isGranted from '../../hoc/isGranted'
import { OWNER, ADMIN, CHIEF, ASSISTANT } from '../../constants/user-roles'
import ProjectSidebar from '../ProjectSidebar'
import Settings from '../Settings'

const ProjectPageWrap = styled.div`
  height: calc(100vh - 69px);

  .page-container {
    padding-top: 50px;
    padding-right: 290px;
    padding-left: 30px;
  }

  .ui.sidebar.menu {
    padding-top: 50px;
  }

  .screen-loader {
    left: calc(50% - ${(props) => (props.isSidebarLoaded ? '130px' : 0)}) !important;
  }
`

const Unauthorized = () => (
  <Fragment>
    <Header>Недостаточно прав</Header>
    <Header.Subheader>К сожалению, у вас недостаточно прав для просмотра страницы</Header.Subheader>
  </Fragment>
)
const MembersWithRights = isGranted([OWNER])(Members, Unauthorized)
const IntegrationsWithRights = isGranted([OWNER, ADMIN, CHIEF])(Integrations, Unauthorized)
const ServicesWithRights = isGranted([OWNER, ADMIN, CHIEF, ASSISTANT])(Services, Unauthorized)
const SettingsWithRights = isGranted([OWNER, ADMIN])(Settings, Unauthorized)

const selectContents = (projectId, project, section, onMoveToArchive) => {
  switch (section) {
    case projectMenus.MEMBERS:
      return <MembersWithRights projectId={projectId} project={project} />
    case projectMenus.SERVICES:
      return <ServicesWithRights projectId={projectId} project={project} />
    case projectMenus.INTEGRATIONS:
      return <IntegrationsWithRights projectId={projectId} project={project} />
    case projectMenus.SETTINGS:
      return (
        <SettingsWithRights
          projectId={projectId}
          project={project}
          onMoveToArchive={onMoveToArchive}
        />
      )

    // no default
  }

  throw new Error(`Unexpected section name: ${section}`)
}

const ProjectPage = ({ project, section, selectMenuItem, onMoveToArchive }) => {
  const { isFetching, id: projectId, name } = project

  return (
    <ProjectPageWrap isSidebarLoaded={!!projectId}>
      <Sidebar.Pushable>
        {projectId && (
          <ProjectSidebar
            projectId={projectId}
            currentSection={section}
            onItemClick={selectMenuItem}
          />
        )}
        <Sidebar.Pusher>
          <Container fluid className="page-container">
            {isFetching && !name ? (
              <Dimmer active inverted>
                <Loader size="large" content="Загрузка.." active className="screen-loader" />
              </Dimmer>
            ) : (
              selectContents(projectId, project, section, onMoveToArchive)
            )}
          </Container>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </ProjectPageWrap>
  )
}

ProjectPage.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    createdAt: PropTypes.string,
    isFetching: PropTypes.bool,
  }).isRequired,
  section: PropTypes.string.isRequired,
  selectMenuItem: PropTypes.func.isRequired,
  onMoveToArchive: PropTypes.func.isRequired,
}

export default ProjectPage
