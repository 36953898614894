import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, Grid, Message } from 'semantic-ui-react'
import useFetch from '../../hooks/useFetch'
import isGranted from '../../hoc/isGranted'
import { ADMIN, OWNER } from '../../constants/user-roles'
import * as api from '../../api/yandexDirect'

const itemsToOptions = (items) =>
  items
    .filter((item) => !item.archived)
    .map((item) => ({
      key: item.login,
      text: item.login,
      value: item.login,
      description: item.name,
    }))

const ChooseYandexDirectClient = ({ projectId, isUpdating, select }) => {
  const [selectedClient, setClient] = useState(null)
  const fetchClients = useCallback(() => {
    return api.fetchClients(projectId)
  }, [projectId])
  const [clients, clientsIsFetching] = useFetch(fetchClients, [])

  const onChangeClient = (e, { value }) => {
    setClient(value)
  }

  const onSave = selectedClient
    ? () => {
        select(selectedClient)
      }
    : null

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={14}>
          <Dropdown
            placeholder="Выберите рекламодателя..."
            fluid
            search
            selection
            loading={clientsIsFetching}
            options={itemsToOptions(clients)}
            onChange={onChangeClient}
          />
        </Grid.Column>
        <Grid.Column width={2}>
          <Button
            primary
            content="Сохранить"
            disabled={!selectedClient}
            loading={isUpdating}
            onClick={onSave}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

ChooseYandexDirectClient.defaultProps = {
  isUpdating: false,
}

ChooseYandexDirectClient.propTypes = {
  projectId: PropTypes.string.isRequired,
  select: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
}

const NoRights = () => (
  <Message>Процесс интеграции не завершён: рекламодатель ещё не выбран.</Message>
)

export default isGranted([OWNER, ADMIN])(ChooseYandexDirectClient, NoRights)
